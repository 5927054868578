import React, { useEffect } from 'react'

import { Form, Input } from 'antd'
import { Rule } from 'antd/es/form'
import { FormInstance } from 'antd/es/form/Form'

import { getInputClassName } from '../helpers/Input.helper'

import { InputCommonProps } from '../types/InputCommonProps.interface'
import { INPUTSIZES } from '../types/InputSize.type'

interface TextInputProps extends InputCommonProps {
  value?: string
  placeholder: string
  label?: string
  formName?: string
  onChange?: (value: string) => void
  defaultValue?: string
  addonBefore?: React.ReactNode
  addonAfter?: React.ReactNode
  rules?: Rule[]
  formRef?: FormInstance<any>
}
export const TextInput = ({
  value,
  placeholder,
  label,
  formName,
  size = INPUTSIZES.medium,
  overrideWidth,
  defaultValue,
  addonBefore,
  addonAfter,
  rules,
  formRef,
  disabled,
  onChange,
}: TextInputProps) => {
  useEffect(() => {
    if (formRef && formName) {
      formRef.setFieldsValue({
        [formName]: value || '',
      })
    }
  }, [value])

  return (
    <Form.Item
      label={label}
      name={formName}
      initialValue={value}
      className={overrideWidth ? '' : getInputClassName(size) + ' inter__input--height'}
      rules={rules}
      required={rules && rules.length > 0}
    >
      <Input
        addonBefore={addonBefore}
        addonAfter={addonAfter}
        defaultValue={defaultValue}
        className={overrideWidth ? '' : getInputClassName(size)}
        placeholder={placeholder}
        style={{ width: overrideWidth }}
        value={value}
        disabled={disabled}
        onChange={(e) => onChange && onChange(e.target.value)}
      />
    </Form.Item>
  )
}
