import * as costv1 from '../proto/invoicing/v1/cost_pb'

import { Pagination, defaultPageSize } from './query'

interface CostPage {
  pagination: Pagination
  filter: CostFilter
  sorting: CostSort
}

interface CostFilter {
  bookingRef?: Array<string>
  shipmentRef?: string
  status?: Array<costv1.Cost.Status>
  type?: Array<costv1.Cost.Type>
  search?: string
  organizationId?: Array<number>
  branchId?: Array<number>
  cancelled?: boolean
}

export const getDefaultCostPage = (): CostSearchParameters => ({
  pagination: { skip: 0, limit: defaultPageSize },
  filter: {},
  sorting: {},
})

// A very permissive type so we can convert some indexes to a JSON string in
// address to allow conversion to a query parameter string.
type ParsingCostPage = {
  skip: number
  limit: number
  filter?: string
  // TODO: Merge into filter?
  search?: string
  sorting?: string
}

interface CostsFilter {
  costId?: number[]
  bookingReference?: string[]
  status?: CostStatus[]
  type?: CostType[]
  organization?: number[]
  branchId?: number[]
  shipmentRef?: string
  search?: string
}

interface CostSort {
  field?: CostField
  ordering?: CostOrdering
}

interface CostSearchParameters {
  filter?: CostsFilter
  sorting?: CostSort
  search?: string
  pagination?: Pagination
}

interface Cost {
  costId: number
  organizationId: number
  branchId: number
  status: CostStatus
  type: CostType
  bookingRef: string
  shipmentRef: string
  supplierId: number
  comment: string
  serialNumber: string
  estimatedAmount: number
  actualAmount: number
  currency: string
  currencyDate: string
  convertedCurrency: string
  convertedCurrencyRate: number
  convertedEstimatedAmount: number
  convertedActualAmount: number
  createdAt?: string
  updatedAt?: string
}

enum CostField {
  COST_ID = 0,
  BOOKING_REF = 1,
  STATUS = 2,
  TYPE = 3,
  ESTIMATED_AMOUNT = 4,
  ACTUAL_AMOUNT = 5,
  CREATED_AT = 6,
}

enum CostOrdering {
  UNKNOWN = 0,
  ASC = 1,
  DESC = 2,
}

enum CostStatus {
  NEW = 0,
  IN_REVIEW = 1,
  APPROVED = 2,
  CANCELLED = 3,
}

enum CostType {
  OTHER = 0,
  DOMESTIC = 1,
  TERMINAL = 2,
  CUSTOMS_ADMINISTRATION = 3,
  ARRIVING_FREIGHT = 4,
  DEPARTING_FREIGHT = 5,
  PROFIT_SHARE = 6,
  CUSTOMS_VAT_EXPENSE = 7,
  EXPENSE = 8,
  FERRY = 9,
  TRAILER_COST = 10,
  CLAIM = 11,
}

export type {
  CostsFilter,
  Cost,
  CostSort,
  CostPage,
  ParsingCostPage,
  CostFilter,
  CostSearchParameters,
}
export { CostField, CostOrdering, CostStatus, CostType }
