import { CreateSurchargeType, IRate, ISurcharge } from 'shared/interface/surcharge.interface'

import { Rate, Surcharge } from 'proto/contract/v1/contract_pb'

import { dateStringToTimeStamp, timestampToDateTime } from 'shared/helpers/datetime.helper'

const toSurchargeProto = (surcharge: ISurcharge | CreateSurchargeType): Surcharge => {
  const proto = new Surcharge()
  if ('surchargeId' in surcharge) {
    proto.setSurchargeId(surcharge.surchargeId)
  }
  if ('createdAt' in surcharge && surcharge.createdAt) {
    proto.setCreatedAt(dateStringToTimeStamp(surcharge.createdAt))
  }
  proto.setSurchargeType(surcharge.surchargeType)
  proto.setRate(surcharge.rate)
  proto.setRateType(surcharge.rateType)
  proto.setCurrency(surcharge.currency)
  proto.setOrganizationId(surcharge.organizationId)
  proto.setCategory(surcharge.category)
  proto.setIsActive(surcharge.isActive)
  proto.setMinPrice(surcharge.minPrice)
  proto.setMaxPrice(surcharge.maxPrice)
  return proto
}

const fromSurchargeProto = (surcharge: Surcharge): ISurcharge => {
  return {
    surchargeId: surcharge.getSurchargeId(),
    organizationId: surcharge.getOrganizationId(),
    surchargeType: surcharge.getSurchargeType(),
    rate: surcharge.getRate(),
    rates: fromRatesProto(surcharge.getRatesList()),
    rateType: surcharge.getRateType(),
    currency: surcharge.getCurrency(),
    createdAt: timestampToDateTime(surcharge.getCreatedAt()),
    category: surcharge.getCategory(),
    minPrice: surcharge.getMinPrice(),
    maxPrice: surcharge.getMaxPrice(),
    price: surcharge.getPrice(),
    isActive: surcharge.getIsActive(),
  }
}

export { fromSurchargeProto, toSurchargeProto }

const fromRatesProto = (rate: Rate[]): IRate[] => {
  return rate.map((r) => {
    return {
      spaceQuantity: r.getSpaceQuantity(),
      value: r.getValue(),
    }
  })
}
