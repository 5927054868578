import { Modal } from 'antd'

import { PrimaryButton, SecondaryButton } from 'components/Buttons'
import { Heading } from 'components/Typography'

import './modal.css'

interface ModalProps {
  visible: boolean
  title?: string
  onOk?: () => void
  onCancel: () => void
  okButtonText?: string
  cancelButtonText?: string
  children?: React.JSX.Element
  footer?: React.JSX.Element
  size: 'xs' | 'sm' | 'md' | 'lg'
  header?: React.JSX.Element
  disableOkButton?: boolean
  destroyOnClose?: boolean
  closeOnClickOutside?: boolean
}

export const ModalComponent = ({
  visible,
  title,
  okButtonText = 'Save',
  cancelButtonText = 'Cancel',
  children,
  footer,
  size,
  onOk,
  onCancel,
  header,
  disableOkButton,
  destroyOnClose,
  closeOnClickOutside = true,
}: ModalProps) => {
  const getModalClassNames = (): string => {
    const sizeMap = {
      xs: 'modal__container--xsmall',
      sm: 'modal__container--small ',
      md: 'modal__container',
      lg: 'modal__container--large',
    }
    return sizeMap[size ?? 'md']
  }
  return (
    <Modal
      maskClosable={closeOnClickOutside}
      destroyOnClose={destroyOnClose}
      open={visible}
      title={header ? header : <Heading text={title ?? ''} headingType="h2" />}
      okText={okButtonText}
      cancelText={cancelButtonText}
      onOk={onOk}
      onCancel={onCancel}
      footer={footer}
      className={'modal__dimensions ' + getModalClassNames()}
      transitionName=""
      okButtonProps={{ disabled: disableOkButton }} // Pass disabled property to control OK button state
    >
      {children}
    </Modal>
  )
}

interface FooterProps {
  onCancel: () => void
  onOk: () => void
  okButtonText?: string
  cancelButtonText?: string
  disableOkButton?: boolean
  loading?: boolean
}
const Footer = ({
  onCancel,
  onOk,
  okButtonText = 'Save',
  cancelButtonText = 'Cancel',
  disableOkButton,
  loading,
}: FooterProps) => {
  return (
    <div className="modal__footer">
      <SecondaryButton onClick={onCancel} label={cancelButtonText} />
      <PrimaryButton
        onClick={onOk}
        label={okButtonText}
        disabled={disableOkButton}
        loading={loading}
      />
    </div>
  )
}

ModalComponent.Footer = Footer
