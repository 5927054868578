import { InputSize } from '../types/InputSize.type'

const getInputClassName = (size: InputSize): string => {
  const sizeClassNameRecord: Record<InputSize, string> = {
    max: '--max',
    xxl: '--xxl',
    xl: '--xl',
    lg: '--l',
    md: '--m',
    sm: '--s',
    xs: '--xs',
  }
  return 'inter__input-field' + (sizeClassNameRecord[size] ?? '--m')
}

const inputHeightClass = ' inter__input--height'
export { getInputClassName, inputHeightClass }
