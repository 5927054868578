import { MenuProps } from 'antd'

type MenuItem = Required<MenuProps>['items'][number]

const keyFromPath = (path: string) => {
  const routes = [
    '/orders',
    '/bookings',
    '/drafts',
    '/quotes',
    '/shipments',
    '/reports',
    '/admin',
    '/contracts',
    '/surcharges',
  ]

  const foundRoute = routes.find((route) => path.includes(route))
  return foundRoute ? foundRoute : path
}

export { keyFromPath }

export type { MenuItem }
