import { Cost as pbCost } from 'proto/invoicing/v1/cost_pb'

import { createDateFromString, fromMoment, timestampToMoment } from 'helpers/timestamp'

import { Cost, CostStatus, CostType } from 'types/cost'

const FromCostProto = (cost: pbCost): Cost => {
  const result: Cost = {
    actualAmount: cost.getActualAmount(),
    bookingRef: cost.getBookingRef(),
    branchId: cost.getBranchId(),
    comment: cost.getComment(),
    convertedActualAmount: cost.getConvertedActualAmount(),
    convertedCurrency: cost.getConvertedCurrency(),
    convertedCurrencyRate: cost.getConvertedCurrencyRate(),
    convertedEstimatedAmount: cost.getConvertedEstimatedAmount(),
    costId: cost.getCostId(),
    currencyDate: timestampToMoment(cost.getCurrencyDate()).format(),
    createdAt: timestampToMoment(cost.getCreatedAt()).format(),
    currency: cost.getCurrency(),
    estimatedAmount: cost.getEstimatedAmount(),
    organizationId: cost.getOrganizationId(),
    serialNumber: cost.getSerialNumber(),
    shipmentRef: cost.getShipmentRef(),
    status: cost.getStatus() as unknown as CostStatus,
    supplierId: cost.getSupplierId(),
    type: cost.getType() as unknown as CostType,
    updatedAt: timestampToMoment(cost.getUpdatedAt()).format(),
  }
  return result
}

const ToCostProto = (cost: Cost): pbCost => {
  const result = new pbCost()
  result.setActualAmount(cost.actualAmount)
  result.setBookingRef(cost.bookingRef)
  result.setBranchId(cost.branchId)
  result.setComment(cost.comment)
  result.setConvertedActualAmount(cost.convertedActualAmount)
  result.setConvertedCurrency(cost.convertedCurrency)
  result.setConvertedCurrencyRate(cost.convertedCurrencyRate)
  result.setConvertedEstimatedAmount(cost.convertedEstimatedAmount)
  if (cost.costId > 0) {
    result.setCostId(cost.costId)
  }
  result.setCurrencyDate(fromMoment(createDateFromString(cost.currencyDate)))
  result.setCurrency(cost.currency)
  result.setEstimatedAmount(cost.estimatedAmount)
  result.setOrganizationId(cost.organizationId)
  result.setSerialNumber(cost.serialNumber)
  result.setShipmentRef(cost.shipmentRef)
  result.setStatus(cost.status as unknown as pbCost.Status)
  result.setSupplierId(cost.supplierId)
  result.setType(cost.type as unknown as pbCost.Type)
  if (cost.createdAt) {
    result.setCreatedAt(fromMoment(createDateFromString(cost.createdAt)))
  }
  if (cost.updatedAt) {
    result.setUpdatedAt(fromMoment(createDateFromString(cost.updatedAt)))
  }

  return result
}

export { ToCostProto, FromCostProto }
