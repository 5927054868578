import ReactGA from 'react-ga'

import * as Sentry from '@sentry/browser'
import { Auth0UserProfile } from 'auth0-js'
import { SagaIterator } from 'redux-saga'
import { all, call, put, race, take, takeEvery, takeLatest } from 'redux-saga/effects'

import * as branchv1 from '../proto/iam/v1/branch_pb'
import * as organizationv1 from '../proto/iam/v1/organization_pb'
import * as userv1 from '../proto/iam/v1/user_pb'

import { AUTH_RESP, Actions, LOGIN, LOGOUT, SIGNUP_REQ, VERIFY_TOKEN } from '../store/auth/actions'
import {
  Actions as BranchActions,
  LIST_BRANCHES_ERR,
  LIST_BRANCHES_RESP,
} from '../store/iam/branch/actions'
import {
  LIST_ERR as ORG_LIST_ERR,
  LIST_RESP as ORG_LIST_RESP,
  Actions as OrgActions,
} from '../store/iam/organization/actions'
import {
  GET_ERR as GET_USER_ERR,
  GET_RESP as GET_USER_RESP,
  LIST_PUBLIC_USERS_ERR,
  LIST_PUBLIC_USERS_RESP,
  CREATE_RESP as USER_CREATE_RESP,
  Actions as UserActions,
} from '../store/iam/user/actions'
import {
  LIST_ERR as USER_GROUP_LIST_ERR,
  LIST_RESP as USER_GROUP_LIST_RESP,
  Actions as UserGroupActions,
} from '../store/iam/usergroup/actions'
import { Actions as UiActions } from '../store/ui/config/actions'

import Auth, * as authlib from '../helpers/auth'
import { haveRole } from '../helpers/user'

import { AuthSignup } from '../types/signup'
import { UserConfig } from '../types/userui'

function* login(auth: Auth) {
  yield call([auth, auth.login])
}

export function* signup(auth: Auth, action: ReturnType<typeof Actions.signupReq>) {
  try {
    const email = action.payload.email
    const authSignupCredentials: AuthSignup = yield call([auth, auth.signup], email)
    if (!authSignupCredentials) {
      throw new Error('missing credentials')
    }
    yield put(Actions.signupResp(authSignupCredentials))
  } catch (err: unknown) {
    if (err instanceof Error) {
      yield put(Actions.signupErr(err))
    }
  }
}

function* logout(auth: Auth) {
  yield call([auth, auth.logout])
  yield put(UserActions.setCurrentUser(undefined))
  yield put(UiActions.setUserConfig(undefined))
}

export function* verifyToken() {
  const accessToken: string = yield call(authlib.accessToken)
  if (!accessToken) {
    yield* signOutAndGoToLoginPage()
  }
  if (Date.now() > authlib.expiresAt().getTime()) {
    yield* signOutAndGoToLoginPage()
  }

  function* signOutAndGoToLoginPage() {
    yield put(Actions.logout())
    yield put(Actions.login())
  }
}

function* authenticate(auth: Auth): SagaIterator {
  // Delay to let the auth.logout call (which deletes the tokens) finish.
  // TODO: Could be better handeled with some signaling from logout() (saga channels?).
  // Either load the profile or wait for the login.
  const accessToken: string = yield call(authlib.accessToken)

  yield* accessToken ? refreshTokenAndSetAuthProfile() : retrieveTokenAndSetAuthProfile()
  function* refreshTokenAndSetAuthProfile() {
    // If the token has expired, try to refresh it.
    const expiresAt: Date = authlib.expiresAt()
    const profile = authlib.profile()
    if (Date.now() < expiresAt.getTime() && profile) {
      yield put(Actions.authenticateResp(profile))
    } else {
      try {
        const profile: Auth0UserProfile = yield call([auth, auth.refresh])
        // At this point we must have a user profile.
        setAnalycticsWithProfile(profile)
        yield put(Actions.authenticateResp(profile))
      } catch {
        // In case this is not supported with 3rd party cookies off or with
        // Safari with ITP, fallback gracefully by requiring a full login.
        yield put(Actions.logout())
        yield put(Actions.login())
      }
    }
  }

  function* retrieveTokenAndSetAuthProfile() {
    // Make sure we start with a clean slate (resetting any errors and deleting auth tokens).
    yield put(Actions.authenticateErr(undefined))
    yield call([auth, auth.logout])
    // Wait for the auth to be triggered.
    try {
      const profile: Auth0UserProfile = yield call([auth, auth.authenticate])
      yield put(Actions.authenticateResp(profile))
      setAnalycticsWithProfile(profile)
      // At this point we must have a user profile.
    } catch (err) {
      yield put(Actions.authenticateErr(err))
      yield call([auth, auth.logout])
    }
  }

  function setAnalycticsWithProfile(authProfile: Auth0UserProfile) {
    if (process.env.NODE_ENV !== 'development') {
      // Set Google Analytics custom dimensions.
      ReactGA.set({ userId: authProfile.sub })
      // Set the Sentry.io context.

      Sentry.getCurrentScope().setUser({ id: authProfile.sub, email: authProfile.email })
    }
  }
}

export function* fetchUserAndOrgAndBranch(action: ReturnType<typeof Actions.authenticateResp>) {
  // Fetch all users that the user can see, logout on errors.
  const user: userv1.User | undefined = yield getCurrentUser(action.payload.profile.sub)
  if (!user) {
    yield put(Actions.logout())
    return
  }
  const { hasPublicUsers, hasUserGroups, organization, branch } = yield all({
    hasPublicUsers: getPublicUsers(),
    hasUserGroups: getUserGroups(),
    organization: getCurrentOrganization(user),
    branch: getCurrentBranch(user),
  })

  if (
    !hasPublicUsers ||
    !hasUserGroups ||
    (!branch && !haveRole(userv1.User.Role.ADMIN, user)) ||
    // These 3 types of users could belong to no organization, other types are blocked from this
    (!organization &&
      !(
        haveRole(userv1.User.Role.TRANSPORTER, user) ||
        haveRole(userv1.User.Role.MANAGER, user) ||
        haveRole(userv1.User.Role.ADMIN, user)
      ))
  ) {
    yield put(Actions.logout())
    return
  }

  yield put(UserActions.setCurrentUser(user))
  yield put(OrgActions.setCurrentOrganization(organization))
  yield put(BranchActions.setCurrentBranch(branch))
}

function* getCurrentUser(userId: string) {
  yield put(UserActions.getUserReq(userId))
  const { getUserErr, getUserResp } = yield race({
    getUserErr: take(GET_USER_ERR),
    getUserResp: take(GET_USER_RESP),
  })

  if (getUserErr) {
    yield put(Actions.logout())
    return
  }
  let user: userv1.User = getUserResp.payload.user

  // Wait for the user to be created by the user setup, or cancelled.
  if (!user) {
    const { createResp, cancelCreateUser } = yield race({
      createResp: take(USER_CREATE_RESP),
      cancelCreateUser: take(LOGOUT),
    })
    if (cancelCreateUser) {
      yield put(Actions.logout())
      return
    }
    if (createResp && createResp.payload && createResp.payload.user) {
      user = createResp.payload.user
    }
  }

  // Set UserConfig into UiConfig Object.
  const userConfig = new UserConfig()
  userConfig.configFromJSON(user.getUiConfigJson())
  yield put(UiActions.setUserConfig(userConfig))

  return user
}

function* getCurrentOrganization(user: userv1.User) {
  yield put(OrgActions.listOrganizationsReq(0, 0))

  // List all organization that the user can see.
  const { listOrganizationsResp, listOrganizationsErr } = yield race({
    listOrganizationsResp: take(ORG_LIST_RESP),
    listOrganizationsErr: take(ORG_LIST_ERR),
  })
  if (listOrganizationsErr) {
    yield put(Actions.logout())
    return
  }
  const { organizations }: { [key: string]: Array<organizationv1.Organization> } =
    listOrganizationsResp.payload

  // Set the current organization.
  const organization = organizations.find(
    (o) => user && o.getOrganizationId() === user.getOrganizationId(),
  )

  return organization
}

function* getPublicUsers() {
  yield put(UserActions.listPublicUsersReq(0, 0))
  // List all public users that the current user can see
  const { listPublicUsersErr } = yield race({
    listPublicUsersErr: take(LIST_PUBLIC_USERS_ERR),
    listPublicUsersResp: take(LIST_PUBLIC_USERS_RESP),
  })
  if (listPublicUsersErr) {
    yield put(Actions.logout())
    return false
  }
  return true
}

function* getCurrentBranch(user: userv1.User) {
  yield put(BranchActions.listBranchesReq(0, 0))
  // List all branches that the user can see.
  const { listBranchResp, listBranchErr } = yield race({
    listBranchResp: take(LIST_BRANCHES_RESP),
    listBranchErr: take(LIST_BRANCHES_ERR),
  })

  if (listBranchErr) {
    yield put(Actions.logout())
    return
  }
  const { branches }: { [key: string]: Array<branchv1.Branch> } = listBranchResp.payload

  // Set the current branch.
  const branch = branches.find((b) => user && b.getBranchId() === user.getBranchId())
  return branch
}

function* getUserGroups() {
  yield put(UserGroupActions.listUserGroupsReq(0, 0))
  const { listUserGroupErr } = yield race({
    listUserGroupResp: take(USER_GROUP_LIST_RESP),
    listUserGroupErr: take(USER_GROUP_LIST_ERR),
  })
  if (listUserGroupErr) {
    yield put(Actions.logout())
    return false
  }
  return true
}

export default function* sagas() {
  const domain = window.env.AUTH0_DOMAIN
  const audience = window.env.AUTH0_AUDIENCE
  const clientID = window.env.AUTH0_CLIENT_ID
  const connection = window.env.AUTH0_CONNECTION
  const auth = new Auth(domain, audience, clientID, connection)
  yield takeEvery(LOGIN, login, auth)
  yield takeEvery(LOGOUT, logout, auth)
  yield takeEvery(AUTH_RESP, fetchUserAndOrgAndBranch)
  yield call(authenticate, auth)
  yield takeLatest(SIGNUP_REQ, signup, auth)
  yield takeEvery(VERIFY_TOKEN, verifyToken)
}
