import * as costTypes from 'types/cost'
import { Cost } from 'types/cost'

import * as actions from './actions'

export interface State {
  readonly pages: Map<costTypes.CostSearchParameters, Array<number>>
  readonly costs: Map<number, Cost>
  readonly count: number
  readonly isFetching: boolean
  readonly err?: Error
}

const initialState: State = {
  pages: new Map(),
  costs: new Map(),
  count: 0,
  isFetching: false,
  err: undefined,
}

export default function reducer(s: State = initialState, action: actions.ActionTypes): State {
  switch (action.type) {
    case actions.LIST_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.LIST_RESP: {
      const { count, costs, page } = action.payload

      const costsResult = s.costs.size === 0 ? new Map() : new Map(s.costs)
      costs.forEach((cost) => {
        if (!costsResult.has(cost.costId)) {
          // Add new cost from response
          costsResult.set(cost.costId, cost)
        } else if (costsResult.has(cost.costId)) {
          // Update existing cost from response
          costsResult.set(cost.costId, costsResult.get(cost.costId) as Cost)
        }
      })

      const pageItems = costs.map((i) => i.costId)

      const pages = s.pages

      // TODO reason behind this is that pages.set() will not overwrite page,
      // we are using an object as an index, and pages.set() does not makes a
      // deep equal comparison when checking for the indexes. In an ideal world,
      // we wouldn't need this :(
      pages.forEach((value, key) => {
        if (Object.entries(key).toString() === Object.entries(page).toString()) {
          pages.delete(key)
        }
      })

      pages.set(page, pageItems)
      return { ...s, costs: costsResult, count, isFetching: false, pages }
    }

    case actions.LIST_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.SET_BOOKING_COSTS_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.SET_BOOKING_COSTS_RESP: {
      const { costs } = action.payload
      const currenctCosts = s.costs.size === 0 ? new Map() : new Map(s.costs)
      costs.forEach((i) => {
        currenctCosts.set(i.costId, i)
      })
      return { ...s, costs: currenctCosts, isFetching: false }
    }

    case actions.SET_BOOKING_COSTS_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    case actions.SET_COST_STATUS_REQ: {
      return { ...s, err: undefined, isFetching: true }
    }

    case actions.SET_COST_STATUS_RESP: {
      const { cost } = action.payload
      const currentCosts = s.costs.size === 0 ? new Map() : new Map(s.costs)

      currentCosts.set(cost.costId, cost)
      return { ...s, costs: currentCosts, isFetching: false }
    }

    case actions.SET_COST_STATUS_ERR: {
      const { err } = action.payload
      return { ...s, err, isFetching: false }
    }

    default:
      return s
  }
}
