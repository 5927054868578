import React from 'react'

import { SearchOutlined } from '@ant-design/icons'
import { Input } from 'antd'

import { getInputClassName } from '../helpers/Input.helper'

import { InputCommonProps } from '../types/InputCommonProps.interface'
import { INPUTSIZES } from '../types/InputSize.type'

interface SearchProps extends InputCommonProps {
  placeholder: string
  onChange?: (value: string) => void
  onFinish?: (value: string) => void
  key?: string
}

export const SearchComponent = ({
  placeholder,
  key,
  size = INPUTSIZES.extraLarge,
  overrideWidth,
  onChange,
  onFinish,
}: SearchProps) => {
  return (
    <Input
      key={key}
      placeholder={placeholder}
      prefix={<SearchOutlined />}
      onChange={(e) => {
        if (onChange) {
          onChange(e.target.value)
        }
      }}
      onPressEnter={(e) => {
        if (onFinish) {
          onFinish(e.currentTarget.value)
        }
      }}
      className={overrideWidth ? '' : getInputClassName(size)}
    />
  )
}
