import React, { useEffect, useMemo, useState } from 'react'

import {
  DownOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  UpOutlined,
} from '@ant-design/icons'
import { LoadingOutlined } from '@ant-design/icons'
import { Button, Table, TablePaginationConfig } from 'antd'
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/es/table/interface'

import {
  getColumnWidthDependingOnColumnType,
  getSpecificColumnTypeTextAlignment,
} from './helpers/table.helpers'

import { TableColumns } from './types/table-column.interface'
import { DataType } from './types/table-data.type'

import { NumberColumn } from './NumberColumn'
import { TextColumn } from './TextColumn'
import { useExpandedRow } from './hooks/expanded-row.hook'
import './tableComponent.css'

interface TableProps<T extends Record<string | number, unknown>> {
  data: DataType<T>[]
  pagination?: TablePaginationConfig | false
  rowsize?: 'small' | 'middle' | 'large'
  onChange?: (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<DataType<T>> | SorterResult<DataType<T>>[],
    extra?: TableCurrentDataSource<DataType<T>>,
  ) => void
  columns: TableColumns<T>[]
  multipleExpandableRows?: boolean
  footer?: React.JSX.Element
  noDataText?: React.JSX.Element | string
  loading?: boolean
  expandRowByClick?: boolean
  automaticYScroll?: number // if truthy this component will attempt to make the table scrollable based on the window height
  expandableChild?: (item: T) => React.JSX.Element
  alternativeExpandIcon?: boolean
  disableRowExpand?: (record: T) => boolean
}

export const TableComponent = <T extends Record<string | number, any>>({
  data,
  pagination = false,
  rowsize = 'middle',
  onChange,
  columns,
  multipleExpandableRows = false,
  footer,
  noDataText,
  loading = false,
  expandRowByClick = true,
  automaticYScroll,
  expandableChild,
  alternativeExpandIcon = false,
  disableRowExpand,
}: TableProps<T>) => {
  const { expandedRows, addOrRemoveKeyFromExpandedRow } = useExpandedRow(multipleExpandableRows)

  const getRenderer = (
    column: TableColumns<T>,
    text: string,
    record: T,
    index: number,
  ): React.JSX.Element => {
    const columnTypeMap = {
      numberColumn: <NumberColumn value={text} />,
      textColumn: <TextColumn value={text} />,
      customActions: column.render ? column.render(text, record, index) : <>{text}</>,
      actionRight: column.render ? column.render(text, record, index) : <>{text}</>,
      actionCenter: column.render ? column.render(text, record, index) : <>{text}</>,
      custom: column.render ? column.render(text, record, index) : <>{text}</>,
    }

    const columnTypeToRender = column.render ? 'custom' : column.columnType
    return <>{columnTypeMap[columnTypeToRender || 'textColumn']}</>
  }

  const memoizedColumns: any[] = useMemo(() => {
    return columns?.map((column) => {
      return {
        ...column,
        render: (text: string, record: T, index: number) =>
          getRenderer(column, text, record, index),
        width: column.width ?? getColumnWidthDependingOnColumnType(column),
        align: getSpecificColumnTypeTextAlignment(column),
      }
    })
  }, [columns])

  const [windowHeight, setWindowHeight] = useState(window.innerHeight)

  useEffect(() => {
    const handleResize = () => setWindowHeight(window.innerHeight)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const expandIcon = (expanded: boolean, onExpand: any, record: DataType<T>) => {
    if (alternativeExpandIcon) {
      return expanded ? (
        <MinusCircleOutlined onClick={(e) => onExpand(record, e)} />
      ) : (
        <PlusCircleOutlined onClick={(e) => onExpand(record, e)} />
      )
    }

    return expanded ? (
      <UpOutlined onClick={(e) => onExpand(record, e)} />
    ) : (
      <DownOutlined onClick={(e) => onExpand(record, e)} />
    )
  }

  return (
    <Table<DataType<T>>
      className="table-component--bgw"
      onChange={onChange}
      pagination={pagination}
      dataSource={data}
      summary={() => footer}
      columns={memoizedColumns}
      expandedRowKeys={expandedRows}
      rowKey={(record) => record.key}
      locale={{
        emptyText: noDataText,
      }}
      size={rowsize}
      loading={{
        spinning: loading,
        indicator: <LoadingOutlined style={{ fontSize: 48 }} spin={true} />,
      }}
      scroll={{ x: true, y: automaticYScroll ? windowHeight - automaticYScroll : undefined }}
      expandable={
        expandableChild
          ? {
              expandRowByClick: expandRowByClick,
              expandedRowRender: (item) => (expandableChild ? expandableChild(item) : null),
              rowExpandable: (record) => (disableRowExpand ? !disableRowExpand(record) : true),
              onExpand: (expanded, record) => {
                addOrRemoveKeyFromExpandedRow(expanded, record.key)
              },
              expandIcon: ({ expanded, onExpand, record }) => {
                if (!disableRowExpand) {
                  return expandIcon(expanded, onExpand, record)
                }
                if (!disableRowExpand(record)) {
                  return expandIcon(expanded, onExpand, record)
                }
                return <></>
              },
            }
          : undefined
      }
    />
  )
}

const RightAlignedNumberColumn: React.FC<{ value: string }> = ({ value }) => {
  return <div style={{ textAlign: 'right' }}>{value}</div>
}

const TextColumnComponent: React.FC<{ value: string }> = ({ value }) => {
  return <span>{value}</span>
}

const ActionColumnComponent: React.FC<{
  icon: React.JSX.Element
  disabled?: boolean
  onClick: () => void
}> = ({ icon, disabled, onClick }) => {
  return <Button size="small" icon={icon} onClick={onClick} disabled={disabled} />
}

TableComponent.NumberColumn = RightAlignedNumberColumn
TableComponent.TextColumn = TextColumnComponent
TableComponent.ActionButton = ActionColumnComponent
