import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Outlet } from 'react-router-dom'

import { useLDClient } from 'launchdarkly-react-client-sdk'
import { AnalyticsComponent } from 'route/analytics'

import { Actions as AuthActions } from '../store/auth/actions'
import * as organizationSelectors from '../store/iam/organization/reducer'
import { Actions as UserActions } from '../store/iam/user/actions'
import * as userSelectors from '../store/iam/user/reducer'
import { useAppSelector } from 'store'

import { ContentLayout } from 'components/Layouts/ContentLayout'

import { Header } from './Header'
import './Main.css'
import { Notifications } from './Notifications'
import { Sidebar } from './Sidebar'
import { useMenuCollapse } from './useMenuCollapse.hook'

export const Main = () => {
  const dispatch = useDispatch()
  const ldClient = useLDClient()

  const devEnv = process.env.NODE_ENV === 'development'
  const user = useAppSelector(userSelectors.getCurrentUser)
  const organization = useAppSelector(organizationSelectors.getCurrentOrg)
  const [toggleCollapse, getCollapsed] = useMenuCollapse()

  useEffect(() => {
    dispatch(UserActions.setRoles(user?.getRolesList() ?? []))

    if (user) {
      ldClient?.identify({
        kind: 'multi',
        user: {
          key: user.getEmail(),
          ...(organization && { organization: organization.getOrganization() }),
        },
      })
    }
  }, [])

  const handleLogout = () => dispatch(AuthActions.logout())

  return (
    <>
      <div className="app-wrapper">
        <div className="header" id="header">
          <AnalyticsComponent devEnv={devEnv} />
          <Notifications />

          <Header organization={organization} user={user} onLogout={handleLogout} />
        </div>
        <div className="sidebar" id="sider">
          <Sidebar
            collapsed={getCollapsed()}
            onCollapse={toggleCollapse}
            roles={user?.getRolesList() ?? []}
            onLogout={handleLogout}
          />
        </div>

        <ContentLayout className="content">
          <div className="content--shadow--height">
            <Outlet />
          </div>
        </ContentLayout>
      </div>
    </>
  )
}
