import { call, put, race, take, takeLatest } from 'redux-saga/effects'

import * as invoicev1 from '../../proto/invoicing/v1/invoice_pb'

import { Actions as BookingActions } from '../../store/booking/booking/actions'
import {
  Actions as CostActions,
  LIST_ERR as GET_COST_LIST_ERR,
  LIST_RESP as GET_COST_LIST_RESP,
} from '../../store/invoicing/cost/actions'
import {
  LIST_ERR as GET_INVOICE_LIST_ERR,
  LIST_RESP as GET_INVOICE_LIST_RESP,
  Actions as InvoiceActions,
} from '../../store/invoicing/invoice/actions'
import { Actions, GET_PROFITABILITY_LIST_DATA } from '../../store/ui/views/actions'

import { CostStatus } from 'types/cost'

export function* getProfitabilityList(action: ReturnType<typeof Actions.getProfitabilityList>) {
  yield put(
    InvoiceActions.listInvoicesReq({
      pagination: { skip: 0, limit: 0 },
      filter: {
        status: [
          invoicev1.Invoice.Status.DRAFT,
          invoicev1.Invoice.Status.ISSUED,
          invoicev1.Invoice.Status.OVERDUE,
          invoicev1.Invoice.Status.PAID,
          invoicev1.Invoice.Status.PARTIALLY_PAID,
        ],
      },
      sorting: new invoicev1.InvoiceSorting(),
    }),
  )
  const { fetchErr, fetchResp } = yield race({
    fetchErr: take(GET_INVOICE_LIST_ERR),
    fetchResp: take(GET_INVOICE_LIST_RESP),
  })

  let invoices: invoicev1.Invoice[] = []
  if (!fetchErr) {
    invoices = fetchResp.payload.invoices
  }

  if (invoices.length > 0) {
    const refs = invoices.map((i) => {
      return i.getBookingRef()
    })
    yield call(setCostCurrencies, refs)
    const { bookingPage } = action.payload
    if (bookingPage) {
      bookingPage.filter.bookingRef = [...refs]
      yield put(BookingActions.listBookingsReq(bookingPage, false, true))
    }
  }
}

function* setCostCurrencies(bookingRefs: string[]) {
  if (bookingRefs.length > 0) {
    yield put(
      CostActions.listCostsReq({
        pagination: { skip: 0, limit: 0 },
        filter: {
          bookingReference: bookingRefs,
          status: [CostStatus.APPROVED, CostStatus.IN_REVIEW],
        },
        sorting: {},
      }),
    )
    yield race({
      fetchErr: take(GET_COST_LIST_ERR),
      fetchResp: take(GET_COST_LIST_RESP),
    })
  }
}

export default function* sagas() {
  yield takeLatest(GET_PROFITABILITY_LIST_DATA, getProfitabilityList)
}
