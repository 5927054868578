// source: booking/v1/supplier.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var common_booking_pb = require('../../common/booking_pb.js');
goog.object.extend(proto, common_booking_pb);
var common_owner_pb = require('../../common/owner_pb.js');
goog.object.extend(proto, common_owner_pb);
var crm_v1_address_pb = require('../../crm/v1/address_pb.js');
goog.object.extend(proto, crm_v1_address_pb);
goog.exportSymbol('proto.swift.booking.v1.BringServiceData', null, global);
goog.exportSymbol('proto.swift.booking.v1.BringServiceData.ServiceType', null, global);
goog.exportSymbol('proto.swift.booking.v1.BringSupplierData', null, global);
goog.exportSymbol('proto.swift.booking.v1.CreateSupplierRequest', null, global);
goog.exportSymbol('proto.swift.booking.v1.CreateSupplierResponse', null, global);
goog.exportSymbol('proto.swift.booking.v1.CreateSupplierServiceRequest', null, global);
goog.exportSymbol('proto.swift.booking.v1.CreateSupplierServiceResponse', null, global);
goog.exportSymbol('proto.swift.booking.v1.DHLServiceData', null, global);
goog.exportSymbol('proto.swift.booking.v1.DHLServiceData.ServiceType', null, global);
goog.exportSymbol('proto.swift.booking.v1.DHLSupplierData', null, global);
goog.exportSymbol('proto.swift.booking.v1.DeleteSupplierRequest', null, global);
goog.exportSymbol('proto.swift.booking.v1.DeleteSupplierResponse', null, global);
goog.exportSymbol('proto.swift.booking.v1.DeleteSupplierServiceRequest', null, global);
goog.exportSymbol('proto.swift.booking.v1.DeleteSupplierServiceResponse', null, global);
goog.exportSymbol('proto.swift.booking.v1.EditSupplierRequest', null, global);
goog.exportSymbol('proto.swift.booking.v1.EditSupplierResponse', null, global);
goog.exportSymbol('proto.swift.booking.v1.EditSupplierServiceRequest', null, global);
goog.exportSymbol('proto.swift.booking.v1.EditSupplierServiceResponse', null, global);
goog.exportSymbol('proto.swift.booking.v1.FrejaServiceData', null, global);
goog.exportSymbol('proto.swift.booking.v1.FrejaSupplierData', null, global);
goog.exportSymbol('proto.swift.booking.v1.GetSupplierConfigRequest', null, global);
goog.exportSymbol('proto.swift.booking.v1.GetSupplierConfigResponse', null, global);
goog.exportSymbol('proto.swift.booking.v1.GetSupplierRequest', null, global);
goog.exportSymbol('proto.swift.booking.v1.GetSupplierResponse', null, global);
goog.exportSymbol('proto.swift.booking.v1.IntereastServiceData', null, global);
goog.exportSymbol('proto.swift.booking.v1.IntereastSupplierData', null, global);
goog.exportSymbol('proto.swift.booking.v1.K2ServiceData', null, global);
goog.exportSymbol('proto.swift.booking.v1.K2SupplierData', null, global);
goog.exportSymbol('proto.swift.booking.v1.ListConfigsRequest', null, global);
goog.exportSymbol('proto.swift.booking.v1.ListConfigsResponse', null, global);
goog.exportSymbol('proto.swift.booking.v1.ListSuppliersRequest', null, global);
goog.exportSymbol('proto.swift.booking.v1.ListSuppliersResponse', null, global);
goog.exportSymbol('proto.swift.booking.v1.SetSupplierConfigRequest', null, global);
goog.exportSymbol('proto.swift.booking.v1.SetSupplierConfigResponse', null, global);
goog.exportSymbol('proto.swift.booking.v1.Supplier', null, global);
goog.exportSymbol('proto.swift.booking.v1.Supplier.SupplierDataCase', null, global);
goog.exportSymbol('proto.swift.booking.v1.SupplierConfig', null, global);
goog.exportSymbol('proto.swift.booking.v1.SupplierService', null, global);
goog.exportSymbol('proto.swift.booking.v1.SupplierService.ServiceDataCase', null, global);
goog.exportSymbol('proto.swift.booking.v1.SupplierSetting', null, global);
goog.exportSymbol('proto.swift.booking.v1.SupplierType', null, global);
goog.exportSymbol('proto.swift.booking.v1.TemplateServiceData', null, global);
goog.exportSymbol('proto.swift.booking.v1.TemplateSupplierData', null, global);
goog.exportSymbol('proto.swift.booking.v1.UPSServiceData', null, global);
goog.exportSymbol('proto.swift.booking.v1.UPSServiceData.ServiceType', null, global);
goog.exportSymbol('proto.swift.booking.v1.UPSSupplierData', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.Supplier = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.booking.v1.Supplier.repeatedFields_, proto.swift.booking.v1.Supplier.oneofGroups_);
};
goog.inherits(proto.swift.booking.v1.Supplier, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.Supplier.displayName = 'proto.swift.booking.v1.Supplier';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.TemplateSupplierData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.TemplateSupplierData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.TemplateSupplierData.displayName = 'proto.swift.booking.v1.TemplateSupplierData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.BringSupplierData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.BringSupplierData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.BringSupplierData.displayName = 'proto.swift.booking.v1.BringSupplierData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.DHLSupplierData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.DHLSupplierData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.DHLSupplierData.displayName = 'proto.swift.booking.v1.DHLSupplierData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.FrejaSupplierData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.FrejaSupplierData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.FrejaSupplierData.displayName = 'proto.swift.booking.v1.FrejaSupplierData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.IntereastSupplierData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.IntereastSupplierData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.IntereastSupplierData.displayName = 'proto.swift.booking.v1.IntereastSupplierData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.K2SupplierData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.K2SupplierData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.K2SupplierData.displayName = 'proto.swift.booking.v1.K2SupplierData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.UPSSupplierData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.UPSSupplierData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.UPSSupplierData.displayName = 'proto.swift.booking.v1.UPSSupplierData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.SupplierService = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.booking.v1.SupplierService.repeatedFields_, proto.swift.booking.v1.SupplierService.oneofGroups_);
};
goog.inherits(proto.swift.booking.v1.SupplierService, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.SupplierService.displayName = 'proto.swift.booking.v1.SupplierService';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.BringServiceData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.BringServiceData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.BringServiceData.displayName = 'proto.swift.booking.v1.BringServiceData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.TemplateServiceData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.TemplateServiceData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.TemplateServiceData.displayName = 'proto.swift.booking.v1.TemplateServiceData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.DHLServiceData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.booking.v1.DHLServiceData.repeatedFields_, null);
};
goog.inherits(proto.swift.booking.v1.DHLServiceData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.DHLServiceData.displayName = 'proto.swift.booking.v1.DHLServiceData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.FrejaServiceData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.FrejaServiceData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.FrejaServiceData.displayName = 'proto.swift.booking.v1.FrejaServiceData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.IntereastServiceData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.IntereastServiceData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.IntereastServiceData.displayName = 'proto.swift.booking.v1.IntereastServiceData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.K2ServiceData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.K2ServiceData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.K2ServiceData.displayName = 'proto.swift.booking.v1.K2ServiceData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.UPSServiceData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.UPSServiceData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.UPSServiceData.displayName = 'proto.swift.booking.v1.UPSServiceData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.SupplierConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.booking.v1.SupplierConfig.repeatedFields_, null);
};
goog.inherits(proto.swift.booking.v1.SupplierConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.SupplierConfig.displayName = 'proto.swift.booking.v1.SupplierConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.SupplierSetting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.SupplierSetting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.SupplierSetting.displayName = 'proto.swift.booking.v1.SupplierSetting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.ListSuppliersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.ListSuppliersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.ListSuppliersRequest.displayName = 'proto.swift.booking.v1.ListSuppliersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.ListSuppliersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.booking.v1.ListSuppliersResponse.repeatedFields_, null);
};
goog.inherits(proto.swift.booking.v1.ListSuppliersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.ListSuppliersResponse.displayName = 'proto.swift.booking.v1.ListSuppliersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.ListConfigsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.ListConfigsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.ListConfigsRequest.displayName = 'proto.swift.booking.v1.ListConfigsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.ListConfigsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.swift.booking.v1.ListConfigsResponse.repeatedFields_, null);
};
goog.inherits(proto.swift.booking.v1.ListConfigsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.ListConfigsResponse.displayName = 'proto.swift.booking.v1.ListConfigsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.GetSupplierRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.GetSupplierRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.GetSupplierRequest.displayName = 'proto.swift.booking.v1.GetSupplierRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.GetSupplierResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.GetSupplierResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.GetSupplierResponse.displayName = 'proto.swift.booking.v1.GetSupplierResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.GetSupplierConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.GetSupplierConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.GetSupplierConfigRequest.displayName = 'proto.swift.booking.v1.GetSupplierConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.GetSupplierConfigResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.GetSupplierConfigResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.GetSupplierConfigResponse.displayName = 'proto.swift.booking.v1.GetSupplierConfigResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.CreateSupplierRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.CreateSupplierRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.CreateSupplierRequest.displayName = 'proto.swift.booking.v1.CreateSupplierRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.CreateSupplierResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.CreateSupplierResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.CreateSupplierResponse.displayName = 'proto.swift.booking.v1.CreateSupplierResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.CreateSupplierServiceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.CreateSupplierServiceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.CreateSupplierServiceRequest.displayName = 'proto.swift.booking.v1.CreateSupplierServiceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.CreateSupplierServiceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.CreateSupplierServiceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.CreateSupplierServiceResponse.displayName = 'proto.swift.booking.v1.CreateSupplierServiceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.EditSupplierRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.EditSupplierRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.EditSupplierRequest.displayName = 'proto.swift.booking.v1.EditSupplierRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.EditSupplierResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.EditSupplierResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.EditSupplierResponse.displayName = 'proto.swift.booking.v1.EditSupplierResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.EditSupplierServiceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.EditSupplierServiceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.EditSupplierServiceRequest.displayName = 'proto.swift.booking.v1.EditSupplierServiceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.EditSupplierServiceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.EditSupplierServiceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.EditSupplierServiceResponse.displayName = 'proto.swift.booking.v1.EditSupplierServiceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.SetSupplierConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.SetSupplierConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.SetSupplierConfigRequest.displayName = 'proto.swift.booking.v1.SetSupplierConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.SetSupplierConfigResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.SetSupplierConfigResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.SetSupplierConfigResponse.displayName = 'proto.swift.booking.v1.SetSupplierConfigResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.DeleteSupplierRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.DeleteSupplierRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.DeleteSupplierRequest.displayName = 'proto.swift.booking.v1.DeleteSupplierRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.DeleteSupplierResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.DeleteSupplierResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.DeleteSupplierResponse.displayName = 'proto.swift.booking.v1.DeleteSupplierResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.DeleteSupplierServiceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.DeleteSupplierServiceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.DeleteSupplierServiceRequest.displayName = 'proto.swift.booking.v1.DeleteSupplierServiceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.swift.booking.v1.DeleteSupplierServiceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.swift.booking.v1.DeleteSupplierServiceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.swift.booking.v1.DeleteSupplierServiceResponse.displayName = 'proto.swift.booking.v1.DeleteSupplierServiceResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.booking.v1.Supplier.repeatedFields_ = [14];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.swift.booking.v1.Supplier.oneofGroups_ = [[7,8,9,10,11,12,13]];

/**
 * @enum {number}
 */
proto.swift.booking.v1.Supplier.SupplierDataCase = {
  SUPPLIER_DATA_NOT_SET: 0,
  TEMPLATE_DATA: 7,
  BRING_DATA: 8,
  DHL_DATA: 9,
  FREJA_DATA: 10,
  INTEREAST_DATA: 11,
  K2_DATA: 12,
  UPS_DATA: 13
};

/**
 * @return {proto.swift.booking.v1.Supplier.SupplierDataCase}
 */
proto.swift.booking.v1.Supplier.prototype.getSupplierDataCase = function() {
  return /** @type {proto.swift.booking.v1.Supplier.SupplierDataCase} */(jspb.Message.computeOneofCase(this, proto.swift.booking.v1.Supplier.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.Supplier.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.Supplier.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.Supplier} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.Supplier.toObject = function(includeInstance, msg) {
  var f, obj = {
supplierId: jspb.Message.getFieldWithDefault(msg, 1, 0),
owner: (f = msg.getOwner()) && common_owner_pb.Owner.toObject(includeInstance, f),
supplierRef: jspb.Message.getFieldWithDefault(msg, 3, ""),
name: jspb.Message.getFieldWithDefault(msg, 4, ""),
usePricing: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
supplierType: jspb.Message.getFieldWithDefault(msg, 6, 0),
templateData: (f = msg.getTemplateData()) && proto.swift.booking.v1.TemplateSupplierData.toObject(includeInstance, f),
bringData: (f = msg.getBringData()) && proto.swift.booking.v1.BringSupplierData.toObject(includeInstance, f),
dhlData: (f = msg.getDhlData()) && proto.swift.booking.v1.DHLSupplierData.toObject(includeInstance, f),
frejaData: (f = msg.getFrejaData()) && proto.swift.booking.v1.FrejaSupplierData.toObject(includeInstance, f),
intereastData: (f = msg.getIntereastData()) && proto.swift.booking.v1.IntereastSupplierData.toObject(includeInstance, f),
k2Data: (f = msg.getK2Data()) && proto.swift.booking.v1.K2SupplierData.toObject(includeInstance, f),
upsData: (f = msg.getUpsData()) && proto.swift.booking.v1.UPSSupplierData.toObject(includeInstance, f),
servicesList: jspb.Message.toObjectList(msg.getServicesList(),
    proto.swift.booking.v1.SupplierService.toObject, includeInstance),
createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
disableSupplierCost: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
isActive: jspb.Message.getBooleanFieldWithDefault(msg, 18, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.Supplier}
 */
proto.swift.booking.v1.Supplier.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.Supplier;
  return proto.swift.booking.v1.Supplier.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.Supplier} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.Supplier}
 */
proto.swift.booking.v1.Supplier.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSupplierId(value);
      break;
    case 2:
      var value = new common_owner_pb.Owner;
      reader.readMessage(value,common_owner_pb.Owner.deserializeBinaryFromReader);
      msg.setOwner(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplierRef(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUsePricing(value);
      break;
    case 6:
      var value = /** @type {!proto.swift.booking.v1.SupplierType} */ (reader.readEnum());
      msg.setSupplierType(value);
      break;
    case 7:
      var value = new proto.swift.booking.v1.TemplateSupplierData;
      reader.readMessage(value,proto.swift.booking.v1.TemplateSupplierData.deserializeBinaryFromReader);
      msg.setTemplateData(value);
      break;
    case 8:
      var value = new proto.swift.booking.v1.BringSupplierData;
      reader.readMessage(value,proto.swift.booking.v1.BringSupplierData.deserializeBinaryFromReader);
      msg.setBringData(value);
      break;
    case 9:
      var value = new proto.swift.booking.v1.DHLSupplierData;
      reader.readMessage(value,proto.swift.booking.v1.DHLSupplierData.deserializeBinaryFromReader);
      msg.setDhlData(value);
      break;
    case 10:
      var value = new proto.swift.booking.v1.FrejaSupplierData;
      reader.readMessage(value,proto.swift.booking.v1.FrejaSupplierData.deserializeBinaryFromReader);
      msg.setFrejaData(value);
      break;
    case 11:
      var value = new proto.swift.booking.v1.IntereastSupplierData;
      reader.readMessage(value,proto.swift.booking.v1.IntereastSupplierData.deserializeBinaryFromReader);
      msg.setIntereastData(value);
      break;
    case 12:
      var value = new proto.swift.booking.v1.K2SupplierData;
      reader.readMessage(value,proto.swift.booking.v1.K2SupplierData.deserializeBinaryFromReader);
      msg.setK2Data(value);
      break;
    case 13:
      var value = new proto.swift.booking.v1.UPSSupplierData;
      reader.readMessage(value,proto.swift.booking.v1.UPSSupplierData.deserializeBinaryFromReader);
      msg.setUpsData(value);
      break;
    case 14:
      var value = new proto.swift.booking.v1.SupplierService;
      reader.readMessage(value,proto.swift.booking.v1.SupplierService.deserializeBinaryFromReader);
      msg.addServices(value);
      break;
    case 15:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 16:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisableSupplierCost(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsActive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.Supplier.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.Supplier.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.Supplier} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.Supplier.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSupplierId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOwner();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_owner_pb.Owner.serializeBinaryToWriter
    );
  }
  f = message.getSupplierRef();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUsePricing();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getSupplierType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getTemplateData();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.swift.booking.v1.TemplateSupplierData.serializeBinaryToWriter
    );
  }
  f = message.getBringData();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.swift.booking.v1.BringSupplierData.serializeBinaryToWriter
    );
  }
  f = message.getDhlData();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.swift.booking.v1.DHLSupplierData.serializeBinaryToWriter
    );
  }
  f = message.getFrejaData();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.swift.booking.v1.FrejaSupplierData.serializeBinaryToWriter
    );
  }
  f = message.getIntereastData();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.swift.booking.v1.IntereastSupplierData.serializeBinaryToWriter
    );
  }
  f = message.getK2Data();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.swift.booking.v1.K2SupplierData.serializeBinaryToWriter
    );
  }
  f = message.getUpsData();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.swift.booking.v1.UPSSupplierData.serializeBinaryToWriter
    );
  }
  f = message.getServicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.swift.booking.v1.SupplierService.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDisableSupplierCost();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getIsActive();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
};


/**
 * optional int64 supplier_id = 1;
 * @return {number}
 */
proto.swift.booking.v1.Supplier.prototype.getSupplierId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.Supplier} returns this
 */
proto.swift.booking.v1.Supplier.prototype.setSupplierId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional swift.common.Owner owner = 2;
 * @return {?proto.swift.common.Owner}
 */
proto.swift.booking.v1.Supplier.prototype.getOwner = function() {
  return /** @type{?proto.swift.common.Owner} */ (
    jspb.Message.getWrapperField(this, common_owner_pb.Owner, 2));
};


/**
 * @param {?proto.swift.common.Owner|undefined} value
 * @return {!proto.swift.booking.v1.Supplier} returns this
*/
proto.swift.booking.v1.Supplier.prototype.setOwner = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Supplier} returns this
 */
proto.swift.booking.v1.Supplier.prototype.clearOwner = function() {
  return this.setOwner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Supplier.prototype.hasOwner = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string supplier_ref = 3;
 * @return {string}
 */
proto.swift.booking.v1.Supplier.prototype.getSupplierRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.Supplier} returns this
 */
proto.swift.booking.v1.Supplier.prototype.setSupplierRef = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.swift.booking.v1.Supplier.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.Supplier} returns this
 */
proto.swift.booking.v1.Supplier.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool use_pricing = 5;
 * @return {boolean}
 */
proto.swift.booking.v1.Supplier.prototype.getUsePricing = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.booking.v1.Supplier} returns this
 */
proto.swift.booking.v1.Supplier.prototype.setUsePricing = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional SupplierType supplier_type = 6;
 * @return {!proto.swift.booking.v1.SupplierType}
 */
proto.swift.booking.v1.Supplier.prototype.getSupplierType = function() {
  return /** @type {!proto.swift.booking.v1.SupplierType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.swift.booking.v1.SupplierType} value
 * @return {!proto.swift.booking.v1.Supplier} returns this
 */
proto.swift.booking.v1.Supplier.prototype.setSupplierType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional TemplateSupplierData template_data = 7;
 * @return {?proto.swift.booking.v1.TemplateSupplierData}
 */
proto.swift.booking.v1.Supplier.prototype.getTemplateData = function() {
  return /** @type{?proto.swift.booking.v1.TemplateSupplierData} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.TemplateSupplierData, 7));
};


/**
 * @param {?proto.swift.booking.v1.TemplateSupplierData|undefined} value
 * @return {!proto.swift.booking.v1.Supplier} returns this
*/
proto.swift.booking.v1.Supplier.prototype.setTemplateData = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.swift.booking.v1.Supplier.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Supplier} returns this
 */
proto.swift.booking.v1.Supplier.prototype.clearTemplateData = function() {
  return this.setTemplateData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Supplier.prototype.hasTemplateData = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional BringSupplierData bring_data = 8;
 * @return {?proto.swift.booking.v1.BringSupplierData}
 */
proto.swift.booking.v1.Supplier.prototype.getBringData = function() {
  return /** @type{?proto.swift.booking.v1.BringSupplierData} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.BringSupplierData, 8));
};


/**
 * @param {?proto.swift.booking.v1.BringSupplierData|undefined} value
 * @return {!proto.swift.booking.v1.Supplier} returns this
*/
proto.swift.booking.v1.Supplier.prototype.setBringData = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.swift.booking.v1.Supplier.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Supplier} returns this
 */
proto.swift.booking.v1.Supplier.prototype.clearBringData = function() {
  return this.setBringData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Supplier.prototype.hasBringData = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional DHLSupplierData dhl_data = 9;
 * @return {?proto.swift.booking.v1.DHLSupplierData}
 */
proto.swift.booking.v1.Supplier.prototype.getDhlData = function() {
  return /** @type{?proto.swift.booking.v1.DHLSupplierData} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.DHLSupplierData, 9));
};


/**
 * @param {?proto.swift.booking.v1.DHLSupplierData|undefined} value
 * @return {!proto.swift.booking.v1.Supplier} returns this
*/
proto.swift.booking.v1.Supplier.prototype.setDhlData = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.swift.booking.v1.Supplier.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Supplier} returns this
 */
proto.swift.booking.v1.Supplier.prototype.clearDhlData = function() {
  return this.setDhlData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Supplier.prototype.hasDhlData = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional FrejaSupplierData freja_data = 10;
 * @return {?proto.swift.booking.v1.FrejaSupplierData}
 */
proto.swift.booking.v1.Supplier.prototype.getFrejaData = function() {
  return /** @type{?proto.swift.booking.v1.FrejaSupplierData} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.FrejaSupplierData, 10));
};


/**
 * @param {?proto.swift.booking.v1.FrejaSupplierData|undefined} value
 * @return {!proto.swift.booking.v1.Supplier} returns this
*/
proto.swift.booking.v1.Supplier.prototype.setFrejaData = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.swift.booking.v1.Supplier.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Supplier} returns this
 */
proto.swift.booking.v1.Supplier.prototype.clearFrejaData = function() {
  return this.setFrejaData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Supplier.prototype.hasFrejaData = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional IntereastSupplierData intereast_data = 11;
 * @return {?proto.swift.booking.v1.IntereastSupplierData}
 */
proto.swift.booking.v1.Supplier.prototype.getIntereastData = function() {
  return /** @type{?proto.swift.booking.v1.IntereastSupplierData} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.IntereastSupplierData, 11));
};


/**
 * @param {?proto.swift.booking.v1.IntereastSupplierData|undefined} value
 * @return {!proto.swift.booking.v1.Supplier} returns this
*/
proto.swift.booking.v1.Supplier.prototype.setIntereastData = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.swift.booking.v1.Supplier.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Supplier} returns this
 */
proto.swift.booking.v1.Supplier.prototype.clearIntereastData = function() {
  return this.setIntereastData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Supplier.prototype.hasIntereastData = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional K2SupplierData k2_data = 12;
 * @return {?proto.swift.booking.v1.K2SupplierData}
 */
proto.swift.booking.v1.Supplier.prototype.getK2Data = function() {
  return /** @type{?proto.swift.booking.v1.K2SupplierData} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.K2SupplierData, 12));
};


/**
 * @param {?proto.swift.booking.v1.K2SupplierData|undefined} value
 * @return {!proto.swift.booking.v1.Supplier} returns this
*/
proto.swift.booking.v1.Supplier.prototype.setK2Data = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.swift.booking.v1.Supplier.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Supplier} returns this
 */
proto.swift.booking.v1.Supplier.prototype.clearK2Data = function() {
  return this.setK2Data(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Supplier.prototype.hasK2Data = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional UPSSupplierData ups_data = 13;
 * @return {?proto.swift.booking.v1.UPSSupplierData}
 */
proto.swift.booking.v1.Supplier.prototype.getUpsData = function() {
  return /** @type{?proto.swift.booking.v1.UPSSupplierData} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.UPSSupplierData, 13));
};


/**
 * @param {?proto.swift.booking.v1.UPSSupplierData|undefined} value
 * @return {!proto.swift.booking.v1.Supplier} returns this
*/
proto.swift.booking.v1.Supplier.prototype.setUpsData = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.swift.booking.v1.Supplier.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Supplier} returns this
 */
proto.swift.booking.v1.Supplier.prototype.clearUpsData = function() {
  return this.setUpsData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Supplier.prototype.hasUpsData = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * repeated SupplierService services = 14;
 * @return {!Array<!proto.swift.booking.v1.SupplierService>}
 */
proto.swift.booking.v1.Supplier.prototype.getServicesList = function() {
  return /** @type{!Array<!proto.swift.booking.v1.SupplierService>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.booking.v1.SupplierService, 14));
};


/**
 * @param {!Array<!proto.swift.booking.v1.SupplierService>} value
 * @return {!proto.swift.booking.v1.Supplier} returns this
*/
proto.swift.booking.v1.Supplier.prototype.setServicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.swift.booking.v1.SupplierService=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.booking.v1.SupplierService}
 */
proto.swift.booking.v1.Supplier.prototype.addServices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.swift.booking.v1.SupplierService, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.booking.v1.Supplier} returns this
 */
proto.swift.booking.v1.Supplier.prototype.clearServicesList = function() {
  return this.setServicesList([]);
};


/**
 * optional google.protobuf.Timestamp created_at = 15;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.booking.v1.Supplier.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 15));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.booking.v1.Supplier} returns this
*/
proto.swift.booking.v1.Supplier.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Supplier} returns this
 */
proto.swift.booking.v1.Supplier.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Supplier.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 16;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.booking.v1.Supplier.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 16));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.booking.v1.Supplier} returns this
*/
proto.swift.booking.v1.Supplier.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.Supplier} returns this
 */
proto.swift.booking.v1.Supplier.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.Supplier.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional bool disable_supplier_cost = 17;
 * @return {boolean}
 */
proto.swift.booking.v1.Supplier.prototype.getDisableSupplierCost = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.booking.v1.Supplier} returns this
 */
proto.swift.booking.v1.Supplier.prototype.setDisableSupplierCost = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional bool is_active = 18;
 * @return {boolean}
 */
proto.swift.booking.v1.Supplier.prototype.getIsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.booking.v1.Supplier} returns this
 */
proto.swift.booking.v1.Supplier.prototype.setIsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.TemplateSupplierData.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.TemplateSupplierData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.TemplateSupplierData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.TemplateSupplierData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.TemplateSupplierData}
 */
proto.swift.booking.v1.TemplateSupplierData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.TemplateSupplierData;
  return proto.swift.booking.v1.TemplateSupplierData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.TemplateSupplierData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.TemplateSupplierData}
 */
proto.swift.booking.v1.TemplateSupplierData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.TemplateSupplierData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.TemplateSupplierData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.TemplateSupplierData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.TemplateSupplierData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.BringSupplierData.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.BringSupplierData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.BringSupplierData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.BringSupplierData.toObject = function(includeInstance, msg) {
  var f, obj = {
userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
apiKey: jspb.Message.getFieldWithDefault(msg, 2, ""),
customerNumber: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.BringSupplierData}
 */
proto.swift.booking.v1.BringSupplierData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.BringSupplierData;
  return proto.swift.booking.v1.BringSupplierData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.BringSupplierData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.BringSupplierData}
 */
proto.swift.booking.v1.BringSupplierData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setApiKey(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.BringSupplierData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.BringSupplierData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.BringSupplierData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.BringSupplierData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getApiKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCustomerNumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.swift.booking.v1.BringSupplierData.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.BringSupplierData} returns this
 */
proto.swift.booking.v1.BringSupplierData.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string api_key = 2;
 * @return {string}
 */
proto.swift.booking.v1.BringSupplierData.prototype.getApiKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.BringSupplierData} returns this
 */
proto.swift.booking.v1.BringSupplierData.prototype.setApiKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string customer_number = 3;
 * @return {string}
 */
proto.swift.booking.v1.BringSupplierData.prototype.getCustomerNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.BringSupplierData} returns this
 */
proto.swift.booking.v1.BringSupplierData.prototype.setCustomerNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.DHLSupplierData.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.DHLSupplierData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.DHLSupplierData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.DHLSupplierData.toObject = function(includeInstance, msg) {
  var f, obj = {
username: jspb.Message.getFieldWithDefault(msg, 1, ""),
password: jspb.Message.getFieldWithDefault(msg, 2, ""),
exportAccountNumber: jspb.Message.getFieldWithDefault(msg, 3, ""),
importAccountNumber: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.DHLSupplierData}
 */
proto.swift.booking.v1.DHLSupplierData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.DHLSupplierData;
  return proto.swift.booking.v1.DHLSupplierData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.DHLSupplierData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.DHLSupplierData}
 */
proto.swift.booking.v1.DHLSupplierData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setExportAccountNumber(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setImportAccountNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.DHLSupplierData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.DHLSupplierData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.DHLSupplierData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.DHLSupplierData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExportAccountNumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getImportAccountNumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string username = 1;
 * @return {string}
 */
proto.swift.booking.v1.DHLSupplierData.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.DHLSupplierData} returns this
 */
proto.swift.booking.v1.DHLSupplierData.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.swift.booking.v1.DHLSupplierData.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.DHLSupplierData} returns this
 */
proto.swift.booking.v1.DHLSupplierData.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string export_account_number = 3;
 * @return {string}
 */
proto.swift.booking.v1.DHLSupplierData.prototype.getExportAccountNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.DHLSupplierData} returns this
 */
proto.swift.booking.v1.DHLSupplierData.prototype.setExportAccountNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string import_account_number = 4;
 * @return {string}
 */
proto.swift.booking.v1.DHLSupplierData.prototype.getImportAccountNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.DHLSupplierData} returns this
 */
proto.swift.booking.v1.DHLSupplierData.prototype.setImportAccountNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.FrejaSupplierData.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.FrejaSupplierData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.FrejaSupplierData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.FrejaSupplierData.toObject = function(includeInstance, msg) {
  var f, obj = {
sftphost: jspb.Message.getFieldWithDefault(msg, 1, ""),
sftphostpubkey: jspb.Message.getFieldWithDefault(msg, 2, ""),
username: jspb.Message.getFieldWithDefault(msg, 3, ""),
password: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.FrejaSupplierData}
 */
proto.swift.booking.v1.FrejaSupplierData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.FrejaSupplierData;
  return proto.swift.booking.v1.FrejaSupplierData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.FrejaSupplierData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.FrejaSupplierData}
 */
proto.swift.booking.v1.FrejaSupplierData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSftphost(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSftphostpubkey(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.FrejaSupplierData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.FrejaSupplierData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.FrejaSupplierData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.FrejaSupplierData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSftphost();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSftphostpubkey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string sftpHost = 1;
 * @return {string}
 */
proto.swift.booking.v1.FrejaSupplierData.prototype.getSftphost = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.FrejaSupplierData} returns this
 */
proto.swift.booking.v1.FrejaSupplierData.prototype.setSftphost = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sftpHostPubKey = 2;
 * @return {string}
 */
proto.swift.booking.v1.FrejaSupplierData.prototype.getSftphostpubkey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.FrejaSupplierData} returns this
 */
proto.swift.booking.v1.FrejaSupplierData.prototype.setSftphostpubkey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string username = 3;
 * @return {string}
 */
proto.swift.booking.v1.FrejaSupplierData.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.FrejaSupplierData} returns this
 */
proto.swift.booking.v1.FrejaSupplierData.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string password = 4;
 * @return {string}
 */
proto.swift.booking.v1.FrejaSupplierData.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.FrejaSupplierData} returns this
 */
proto.swift.booking.v1.FrejaSupplierData.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.IntereastSupplierData.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.IntereastSupplierData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.IntereastSupplierData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.IntereastSupplierData.toObject = function(includeInstance, msg) {
  var f, obj = {
haulerName: jspb.Message.getFieldWithDefault(msg, 1, ""),
address: (f = msg.getAddress()) && crm_v1_address_pb.Address.toObject(includeInstance, f),
legacyId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.IntereastSupplierData}
 */
proto.swift.booking.v1.IntereastSupplierData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.IntereastSupplierData;
  return proto.swift.booking.v1.IntereastSupplierData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.IntereastSupplierData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.IntereastSupplierData}
 */
proto.swift.booking.v1.IntereastSupplierData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHaulerName(value);
      break;
    case 2:
      var value = new crm_v1_address_pb.Address;
      reader.readMessage(value,crm_v1_address_pb.Address.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLegacyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.IntereastSupplierData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.IntereastSupplierData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.IntereastSupplierData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.IntereastSupplierData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHaulerName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      crm_v1_address_pb.Address.serializeBinaryToWriter
    );
  }
  f = message.getLegacyId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string hauler_name = 1;
 * @return {string}
 */
proto.swift.booking.v1.IntereastSupplierData.prototype.getHaulerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.IntereastSupplierData} returns this
 */
proto.swift.booking.v1.IntereastSupplierData.prototype.setHaulerName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional swift.crm.v1.Address address = 2;
 * @return {?proto.swift.crm.v1.Address}
 */
proto.swift.booking.v1.IntereastSupplierData.prototype.getAddress = function() {
  return /** @type{?proto.swift.crm.v1.Address} */ (
    jspb.Message.getWrapperField(this, crm_v1_address_pb.Address, 2));
};


/**
 * @param {?proto.swift.crm.v1.Address|undefined} value
 * @return {!proto.swift.booking.v1.IntereastSupplierData} returns this
*/
proto.swift.booking.v1.IntereastSupplierData.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.IntereastSupplierData} returns this
 */
proto.swift.booking.v1.IntereastSupplierData.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.IntereastSupplierData.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string legacy_id = 3;
 * @return {string}
 */
proto.swift.booking.v1.IntereastSupplierData.prototype.getLegacyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.IntereastSupplierData} returns this
 */
proto.swift.booking.v1.IntereastSupplierData.prototype.setLegacyId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.K2SupplierData.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.K2SupplierData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.K2SupplierData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.K2SupplierData.toObject = function(includeInstance, msg) {
  var f, obj = {
apiUrl: jspb.Message.getFieldWithDefault(msg, 1, ""),
username: jspb.Message.getFieldWithDefault(msg, 2, ""),
password: jspb.Message.getFieldWithDefault(msg, 3, ""),
customerCode: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.K2SupplierData}
 */
proto.swift.booking.v1.K2SupplierData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.K2SupplierData;
  return proto.swift.booking.v1.K2SupplierData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.K2SupplierData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.K2SupplierData}
 */
proto.swift.booking.v1.K2SupplierData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setApiUrl(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.K2SupplierData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.K2SupplierData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.K2SupplierData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.K2SupplierData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getApiUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCustomerCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string api_url = 1;
 * @return {string}
 */
proto.swift.booking.v1.K2SupplierData.prototype.getApiUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.K2SupplierData} returns this
 */
proto.swift.booking.v1.K2SupplierData.prototype.setApiUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string username = 2;
 * @return {string}
 */
proto.swift.booking.v1.K2SupplierData.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.K2SupplierData} returns this
 */
proto.swift.booking.v1.K2SupplierData.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string password = 3;
 * @return {string}
 */
proto.swift.booking.v1.K2SupplierData.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.K2SupplierData} returns this
 */
proto.swift.booking.v1.K2SupplierData.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string customer_code = 4;
 * @return {string}
 */
proto.swift.booking.v1.K2SupplierData.prototype.getCustomerCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.K2SupplierData} returns this
 */
proto.swift.booking.v1.K2SupplierData.prototype.setCustomerCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.UPSSupplierData.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.UPSSupplierData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.UPSSupplierData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.UPSSupplierData.toObject = function(includeInstance, msg) {
  var f, obj = {
accessToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
password: jspb.Message.getFieldWithDefault(msg, 3, ""),
customerAccount: jspb.Message.getFieldWithDefault(msg, 4, ""),
clientId: jspb.Message.getFieldWithDefault(msg, 5, ""),
clientSecret: jspb.Message.getFieldWithDefault(msg, 6, ""),
splitVatInvoicing: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
rs1Returns: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
accountAddress: (f = msg.getAccountAddress()) && crm_v1_address_pb.Address.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.UPSSupplierData}
 */
proto.swift.booking.v1.UPSSupplierData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.UPSSupplierData;
  return proto.swift.booking.v1.UPSSupplierData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.UPSSupplierData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.UPSSupplierData}
 */
proto.swift.booking.v1.UPSSupplierData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerAccount(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientSecret(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSplitVatInvoicing(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRs1Returns(value);
      break;
    case 9:
      var value = new crm_v1_address_pb.Address;
      reader.readMessage(value,crm_v1_address_pb.Address.deserializeBinaryFromReader);
      msg.setAccountAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.UPSSupplierData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.UPSSupplierData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.UPSSupplierData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.UPSSupplierData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccessToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCustomerAccount();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getClientId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getClientSecret();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSplitVatInvoicing();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getRs1Returns();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getAccountAddress();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      crm_v1_address_pb.Address.serializeBinaryToWriter
    );
  }
};


/**
 * optional string access_token = 1;
 * @return {string}
 */
proto.swift.booking.v1.UPSSupplierData.prototype.getAccessToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.UPSSupplierData} returns this
 */
proto.swift.booking.v1.UPSSupplierData.prototype.setAccessToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.swift.booking.v1.UPSSupplierData.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.UPSSupplierData} returns this
 */
proto.swift.booking.v1.UPSSupplierData.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string password = 3;
 * @return {string}
 */
proto.swift.booking.v1.UPSSupplierData.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.UPSSupplierData} returns this
 */
proto.swift.booking.v1.UPSSupplierData.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string customer_account = 4;
 * @return {string}
 */
proto.swift.booking.v1.UPSSupplierData.prototype.getCustomerAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.UPSSupplierData} returns this
 */
proto.swift.booking.v1.UPSSupplierData.prototype.setCustomerAccount = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string client_id = 5;
 * @return {string}
 */
proto.swift.booking.v1.UPSSupplierData.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.UPSSupplierData} returns this
 */
proto.swift.booking.v1.UPSSupplierData.prototype.setClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string client_secret = 6;
 * @return {string}
 */
proto.swift.booking.v1.UPSSupplierData.prototype.getClientSecret = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.UPSSupplierData} returns this
 */
proto.swift.booking.v1.UPSSupplierData.prototype.setClientSecret = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool split_vat_invoicing = 7;
 * @return {boolean}
 */
proto.swift.booking.v1.UPSSupplierData.prototype.getSplitVatInvoicing = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.booking.v1.UPSSupplierData} returns this
 */
proto.swift.booking.v1.UPSSupplierData.prototype.setSplitVatInvoicing = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool rs1_returns = 8;
 * @return {boolean}
 */
proto.swift.booking.v1.UPSSupplierData.prototype.getRs1Returns = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.booking.v1.UPSSupplierData} returns this
 */
proto.swift.booking.v1.UPSSupplierData.prototype.setRs1Returns = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional swift.crm.v1.Address account_address = 9;
 * @return {?proto.swift.crm.v1.Address}
 */
proto.swift.booking.v1.UPSSupplierData.prototype.getAccountAddress = function() {
  return /** @type{?proto.swift.crm.v1.Address} */ (
    jspb.Message.getWrapperField(this, crm_v1_address_pb.Address, 9));
};


/**
 * @param {?proto.swift.crm.v1.Address|undefined} value
 * @return {!proto.swift.booking.v1.UPSSupplierData} returns this
*/
proto.swift.booking.v1.UPSSupplierData.prototype.setAccountAddress = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.UPSSupplierData} returns this
 */
proto.swift.booking.v1.UPSSupplierData.prototype.clearAccountAddress = function() {
  return this.setAccountAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.UPSSupplierData.prototype.hasAccountAddress = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.booking.v1.SupplierService.repeatedFields_ = [5];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.swift.booking.v1.SupplierService.oneofGroups_ = [[6,7,8,9,10,11,12]];

/**
 * @enum {number}
 */
proto.swift.booking.v1.SupplierService.ServiceDataCase = {
  SERVICE_DATA_NOT_SET: 0,
  TEMPLATE_DATA: 6,
  BRING_DATA: 7,
  DHL_DATA: 8,
  FREJA_DATA: 9,
  INTEREAST_DATA: 10,
  K2_DATA: 11,
  UPS_DATA: 12
};

/**
 * @return {proto.swift.booking.v1.SupplierService.ServiceDataCase}
 */
proto.swift.booking.v1.SupplierService.prototype.getServiceDataCase = function() {
  return /** @type {proto.swift.booking.v1.SupplierService.ServiceDataCase} */(jspb.Message.computeOneofCase(this, proto.swift.booking.v1.SupplierService.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.SupplierService.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.SupplierService.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.SupplierService} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.SupplierService.toObject = function(includeInstance, msg) {
  var f, obj = {
serviceId: jspb.Message.getFieldWithDefault(msg, 1, 0),
supplierId: jspb.Message.getFieldWithDefault(msg, 2, 0),
description: jspb.Message.getFieldWithDefault(msg, 3, ""),
supplierType: jspb.Message.getFieldWithDefault(msg, 4, 0),
transportModesList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
templateData: (f = msg.getTemplateData()) && proto.swift.booking.v1.TemplateServiceData.toObject(includeInstance, f),
bringData: (f = msg.getBringData()) && proto.swift.booking.v1.BringServiceData.toObject(includeInstance, f),
dhlData: (f = msg.getDhlData()) && proto.swift.booking.v1.DHLServiceData.toObject(includeInstance, f),
frejaData: (f = msg.getFrejaData()) && proto.swift.booking.v1.FrejaServiceData.toObject(includeInstance, f),
intereastData: (f = msg.getIntereastData()) && proto.swift.booking.v1.IntereastServiceData.toObject(includeInstance, f),
k2Data: (f = msg.getK2Data()) && proto.swift.booking.v1.K2ServiceData.toObject(includeInstance, f),
upsData: (f = msg.getUpsData()) && proto.swift.booking.v1.UPSServiceData.toObject(includeInstance, f),
supportsGoodsInvoice: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
isActive: jspb.Message.getBooleanFieldWithDefault(msg, 16, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.SupplierService}
 */
proto.swift.booking.v1.SupplierService.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.SupplierService;
  return proto.swift.booking.v1.SupplierService.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.SupplierService} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.SupplierService}
 */
proto.swift.booking.v1.SupplierService.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setServiceId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSupplierId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {!proto.swift.booking.v1.SupplierType} */ (reader.readEnum());
      msg.setSupplierType(value);
      break;
    case 5:
      var values = /** @type {!Array<!proto.swift.common.TransportMode>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTransportModes(values[i]);
      }
      break;
    case 6:
      var value = new proto.swift.booking.v1.TemplateServiceData;
      reader.readMessage(value,proto.swift.booking.v1.TemplateServiceData.deserializeBinaryFromReader);
      msg.setTemplateData(value);
      break;
    case 7:
      var value = new proto.swift.booking.v1.BringServiceData;
      reader.readMessage(value,proto.swift.booking.v1.BringServiceData.deserializeBinaryFromReader);
      msg.setBringData(value);
      break;
    case 8:
      var value = new proto.swift.booking.v1.DHLServiceData;
      reader.readMessage(value,proto.swift.booking.v1.DHLServiceData.deserializeBinaryFromReader);
      msg.setDhlData(value);
      break;
    case 9:
      var value = new proto.swift.booking.v1.FrejaServiceData;
      reader.readMessage(value,proto.swift.booking.v1.FrejaServiceData.deserializeBinaryFromReader);
      msg.setFrejaData(value);
      break;
    case 10:
      var value = new proto.swift.booking.v1.IntereastServiceData;
      reader.readMessage(value,proto.swift.booking.v1.IntereastServiceData.deserializeBinaryFromReader);
      msg.setIntereastData(value);
      break;
    case 11:
      var value = new proto.swift.booking.v1.K2ServiceData;
      reader.readMessage(value,proto.swift.booking.v1.K2ServiceData.deserializeBinaryFromReader);
      msg.setK2Data(value);
      break;
    case 12:
      var value = new proto.swift.booking.v1.UPSServiceData;
      reader.readMessage(value,proto.swift.booking.v1.UPSServiceData.deserializeBinaryFromReader);
      msg.setUpsData(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSupportsGoodsInvoice(value);
      break;
    case 14:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 15:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsActive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.SupplierService.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.SupplierService.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.SupplierService} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.SupplierService.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSupplierId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSupplierType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getTransportModesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      5,
      f
    );
  }
  f = message.getTemplateData();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.swift.booking.v1.TemplateServiceData.serializeBinaryToWriter
    );
  }
  f = message.getBringData();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.swift.booking.v1.BringServiceData.serializeBinaryToWriter
    );
  }
  f = message.getDhlData();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.swift.booking.v1.DHLServiceData.serializeBinaryToWriter
    );
  }
  f = message.getFrejaData();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.swift.booking.v1.FrejaServiceData.serializeBinaryToWriter
    );
  }
  f = message.getIntereastData();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.swift.booking.v1.IntereastServiceData.serializeBinaryToWriter
    );
  }
  f = message.getK2Data();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.swift.booking.v1.K2ServiceData.serializeBinaryToWriter
    );
  }
  f = message.getUpsData();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.swift.booking.v1.UPSServiceData.serializeBinaryToWriter
    );
  }
  f = message.getSupportsGoodsInvoice();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getIsActive();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
};


/**
 * optional int64 service_id = 1;
 * @return {number}
 */
proto.swift.booking.v1.SupplierService.prototype.getServiceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.SupplierService} returns this
 */
proto.swift.booking.v1.SupplierService.prototype.setServiceId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 supplier_id = 2;
 * @return {number}
 */
proto.swift.booking.v1.SupplierService.prototype.getSupplierId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.SupplierService} returns this
 */
proto.swift.booking.v1.SupplierService.prototype.setSupplierId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.swift.booking.v1.SupplierService.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.SupplierService} returns this
 */
proto.swift.booking.v1.SupplierService.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional SupplierType supplier_type = 4;
 * @return {!proto.swift.booking.v1.SupplierType}
 */
proto.swift.booking.v1.SupplierService.prototype.getSupplierType = function() {
  return /** @type {!proto.swift.booking.v1.SupplierType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.swift.booking.v1.SupplierType} value
 * @return {!proto.swift.booking.v1.SupplierService} returns this
 */
proto.swift.booking.v1.SupplierService.prototype.setSupplierType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * repeated swift.common.TransportMode transport_modes = 5;
 * @return {!Array<!proto.swift.common.TransportMode>}
 */
proto.swift.booking.v1.SupplierService.prototype.getTransportModesList = function() {
  return /** @type {!Array<!proto.swift.common.TransportMode>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<!proto.swift.common.TransportMode>} value
 * @return {!proto.swift.booking.v1.SupplierService} returns this
 */
proto.swift.booking.v1.SupplierService.prototype.setTransportModesList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {!proto.swift.common.TransportMode} value
 * @param {number=} opt_index
 * @return {!proto.swift.booking.v1.SupplierService} returns this
 */
proto.swift.booking.v1.SupplierService.prototype.addTransportModes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.booking.v1.SupplierService} returns this
 */
proto.swift.booking.v1.SupplierService.prototype.clearTransportModesList = function() {
  return this.setTransportModesList([]);
};


/**
 * optional TemplateServiceData template_data = 6;
 * @return {?proto.swift.booking.v1.TemplateServiceData}
 */
proto.swift.booking.v1.SupplierService.prototype.getTemplateData = function() {
  return /** @type{?proto.swift.booking.v1.TemplateServiceData} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.TemplateServiceData, 6));
};


/**
 * @param {?proto.swift.booking.v1.TemplateServiceData|undefined} value
 * @return {!proto.swift.booking.v1.SupplierService} returns this
*/
proto.swift.booking.v1.SupplierService.prototype.setTemplateData = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.swift.booking.v1.SupplierService.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.SupplierService} returns this
 */
proto.swift.booking.v1.SupplierService.prototype.clearTemplateData = function() {
  return this.setTemplateData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.SupplierService.prototype.hasTemplateData = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional BringServiceData bring_data = 7;
 * @return {?proto.swift.booking.v1.BringServiceData}
 */
proto.swift.booking.v1.SupplierService.prototype.getBringData = function() {
  return /** @type{?proto.swift.booking.v1.BringServiceData} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.BringServiceData, 7));
};


/**
 * @param {?proto.swift.booking.v1.BringServiceData|undefined} value
 * @return {!proto.swift.booking.v1.SupplierService} returns this
*/
proto.swift.booking.v1.SupplierService.prototype.setBringData = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.swift.booking.v1.SupplierService.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.SupplierService} returns this
 */
proto.swift.booking.v1.SupplierService.prototype.clearBringData = function() {
  return this.setBringData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.SupplierService.prototype.hasBringData = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional DHLServiceData dhl_data = 8;
 * @return {?proto.swift.booking.v1.DHLServiceData}
 */
proto.swift.booking.v1.SupplierService.prototype.getDhlData = function() {
  return /** @type{?proto.swift.booking.v1.DHLServiceData} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.DHLServiceData, 8));
};


/**
 * @param {?proto.swift.booking.v1.DHLServiceData|undefined} value
 * @return {!proto.swift.booking.v1.SupplierService} returns this
*/
proto.swift.booking.v1.SupplierService.prototype.setDhlData = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.swift.booking.v1.SupplierService.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.SupplierService} returns this
 */
proto.swift.booking.v1.SupplierService.prototype.clearDhlData = function() {
  return this.setDhlData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.SupplierService.prototype.hasDhlData = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional FrejaServiceData freja_data = 9;
 * @return {?proto.swift.booking.v1.FrejaServiceData}
 */
proto.swift.booking.v1.SupplierService.prototype.getFrejaData = function() {
  return /** @type{?proto.swift.booking.v1.FrejaServiceData} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.FrejaServiceData, 9));
};


/**
 * @param {?proto.swift.booking.v1.FrejaServiceData|undefined} value
 * @return {!proto.swift.booking.v1.SupplierService} returns this
*/
proto.swift.booking.v1.SupplierService.prototype.setFrejaData = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.swift.booking.v1.SupplierService.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.SupplierService} returns this
 */
proto.swift.booking.v1.SupplierService.prototype.clearFrejaData = function() {
  return this.setFrejaData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.SupplierService.prototype.hasFrejaData = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional IntereastServiceData intereast_data = 10;
 * @return {?proto.swift.booking.v1.IntereastServiceData}
 */
proto.swift.booking.v1.SupplierService.prototype.getIntereastData = function() {
  return /** @type{?proto.swift.booking.v1.IntereastServiceData} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.IntereastServiceData, 10));
};


/**
 * @param {?proto.swift.booking.v1.IntereastServiceData|undefined} value
 * @return {!proto.swift.booking.v1.SupplierService} returns this
*/
proto.swift.booking.v1.SupplierService.prototype.setIntereastData = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.swift.booking.v1.SupplierService.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.SupplierService} returns this
 */
proto.swift.booking.v1.SupplierService.prototype.clearIntereastData = function() {
  return this.setIntereastData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.SupplierService.prototype.hasIntereastData = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional K2ServiceData k2_data = 11;
 * @return {?proto.swift.booking.v1.K2ServiceData}
 */
proto.swift.booking.v1.SupplierService.prototype.getK2Data = function() {
  return /** @type{?proto.swift.booking.v1.K2ServiceData} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.K2ServiceData, 11));
};


/**
 * @param {?proto.swift.booking.v1.K2ServiceData|undefined} value
 * @return {!proto.swift.booking.v1.SupplierService} returns this
*/
proto.swift.booking.v1.SupplierService.prototype.setK2Data = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.swift.booking.v1.SupplierService.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.SupplierService} returns this
 */
proto.swift.booking.v1.SupplierService.prototype.clearK2Data = function() {
  return this.setK2Data(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.SupplierService.prototype.hasK2Data = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional UPSServiceData ups_data = 12;
 * @return {?proto.swift.booking.v1.UPSServiceData}
 */
proto.swift.booking.v1.SupplierService.prototype.getUpsData = function() {
  return /** @type{?proto.swift.booking.v1.UPSServiceData} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.UPSServiceData, 12));
};


/**
 * @param {?proto.swift.booking.v1.UPSServiceData|undefined} value
 * @return {!proto.swift.booking.v1.SupplierService} returns this
*/
proto.swift.booking.v1.SupplierService.prototype.setUpsData = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.swift.booking.v1.SupplierService.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.SupplierService} returns this
 */
proto.swift.booking.v1.SupplierService.prototype.clearUpsData = function() {
  return this.setUpsData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.SupplierService.prototype.hasUpsData = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional bool supports_goods_invoice = 13;
 * @return {boolean}
 */
proto.swift.booking.v1.SupplierService.prototype.getSupportsGoodsInvoice = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.booking.v1.SupplierService} returns this
 */
proto.swift.booking.v1.SupplierService.prototype.setSupportsGoodsInvoice = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 14;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.booking.v1.SupplierService.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 14));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.booking.v1.SupplierService} returns this
*/
proto.swift.booking.v1.SupplierService.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.SupplierService} returns this
 */
proto.swift.booking.v1.SupplierService.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.SupplierService.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 15;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.booking.v1.SupplierService.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 15));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.booking.v1.SupplierService} returns this
*/
proto.swift.booking.v1.SupplierService.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.SupplierService} returns this
 */
proto.swift.booking.v1.SupplierService.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.SupplierService.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional bool is_active = 16;
 * @return {boolean}
 */
proto.swift.booking.v1.SupplierService.prototype.getIsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.booking.v1.SupplierService} returns this
 */
proto.swift.booking.v1.SupplierService.prototype.setIsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.BringServiceData.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.BringServiceData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.BringServiceData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.BringServiceData.toObject = function(includeInstance, msg) {
  var f, obj = {
serviceType: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.BringServiceData}
 */
proto.swift.booking.v1.BringServiceData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.BringServiceData;
  return proto.swift.booking.v1.BringServiceData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.BringServiceData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.BringServiceData}
 */
proto.swift.booking.v1.BringServiceData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.swift.booking.v1.BringServiceData.ServiceType} */ (reader.readEnum());
      msg.setServiceType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.BringServiceData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.BringServiceData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.BringServiceData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.BringServiceData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.swift.booking.v1.BringServiceData.ServiceType = {
  UNKNOWN: 0,
  BUSINESS_PARCEL: 1,
  BUSINESS_PARCEL_BULK: 2,
  BUSINESS_PALLET: 3,
  BUSINESS_HALFPALLET: 4,
  BUSINESS_QUARTERPALLET: 5,
  EXPRESS_NORDIC_0900: 6,
  EXPRESS_NORDIC_0900_BULK: 7,
  HOME_DELIVERY_PARCEL: 8,
  PICKUP_PARCEL: 9,
  PICKUP_PARCEL_BULK: 10
};

/**
 * optional ServiceType service_type = 1;
 * @return {!proto.swift.booking.v1.BringServiceData.ServiceType}
 */
proto.swift.booking.v1.BringServiceData.prototype.getServiceType = function() {
  return /** @type {!proto.swift.booking.v1.BringServiceData.ServiceType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.swift.booking.v1.BringServiceData.ServiceType} value
 * @return {!proto.swift.booking.v1.BringServiceData} returns this
 */
proto.swift.booking.v1.BringServiceData.prototype.setServiceType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.TemplateServiceData.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.TemplateServiceData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.TemplateServiceData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.TemplateServiceData.toObject = function(includeInstance, msg) {
  var f, obj = {
statusDelivered: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
generateCmr: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.TemplateServiceData}
 */
proto.swift.booking.v1.TemplateServiceData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.TemplateServiceData;
  return proto.swift.booking.v1.TemplateServiceData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.TemplateServiceData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.TemplateServiceData}
 */
proto.swift.booking.v1.TemplateServiceData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatusDelivered(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGenerateCmr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.TemplateServiceData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.TemplateServiceData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.TemplateServiceData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.TemplateServiceData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatusDelivered();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getGenerateCmr();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional bool status_delivered = 1;
 * @return {boolean}
 */
proto.swift.booking.v1.TemplateServiceData.prototype.getStatusDelivered = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.booking.v1.TemplateServiceData} returns this
 */
proto.swift.booking.v1.TemplateServiceData.prototype.setStatusDelivered = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool generate_cmr = 2;
 * @return {boolean}
 */
proto.swift.booking.v1.TemplateServiceData.prototype.getGenerateCmr = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.booking.v1.TemplateServiceData} returns this
 */
proto.swift.booking.v1.TemplateServiceData.prototype.setGenerateCmr = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.booking.v1.DHLServiceData.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.DHLServiceData.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.DHLServiceData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.DHLServiceData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.DHLServiceData.toObject = function(includeInstance, msg) {
  var f, obj = {
serviceType: jspb.Message.getFieldWithDefault(msg, 1, 0),
serviceCodeList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.DHLServiceData}
 */
proto.swift.booking.v1.DHLServiceData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.DHLServiceData;
  return proto.swift.booking.v1.DHLServiceData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.DHLServiceData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.DHLServiceData}
 */
proto.swift.booking.v1.DHLServiceData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.swift.booking.v1.DHLServiceData.ServiceType} */ (reader.readEnum());
      msg.setServiceType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addServiceCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.DHLServiceData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.DHLServiceData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.DHLServiceData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.DHLServiceData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getServiceCodeList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.swift.booking.v1.DHLServiceData.ServiceType = {
  EXPRESS_WORLDWIDE: 0,
  ECONOMY_SELECT: 1,
  EXPRESS: 2,
  EXPRESS_0900: 3,
  EXPRESS_1200: 4,
  EXPRESS_ENVELOPE: 5
};

/**
 * optional ServiceType service_type = 1;
 * @return {!proto.swift.booking.v1.DHLServiceData.ServiceType}
 */
proto.swift.booking.v1.DHLServiceData.prototype.getServiceType = function() {
  return /** @type {!proto.swift.booking.v1.DHLServiceData.ServiceType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.swift.booking.v1.DHLServiceData.ServiceType} value
 * @return {!proto.swift.booking.v1.DHLServiceData} returns this
 */
proto.swift.booking.v1.DHLServiceData.prototype.setServiceType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated string service_code = 2;
 * @return {!Array<string>}
 */
proto.swift.booking.v1.DHLServiceData.prototype.getServiceCodeList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.swift.booking.v1.DHLServiceData} returns this
 */
proto.swift.booking.v1.DHLServiceData.prototype.setServiceCodeList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.swift.booking.v1.DHLServiceData} returns this
 */
proto.swift.booking.v1.DHLServiceData.prototype.addServiceCode = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.booking.v1.DHLServiceData} returns this
 */
proto.swift.booking.v1.DHLServiceData.prototype.clearServiceCodeList = function() {
  return this.setServiceCodeList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.FrejaServiceData.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.FrejaServiceData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.FrejaServiceData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.FrejaServiceData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.FrejaServiceData}
 */
proto.swift.booking.v1.FrejaServiceData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.FrejaServiceData;
  return proto.swift.booking.v1.FrejaServiceData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.FrejaServiceData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.FrejaServiceData}
 */
proto.swift.booking.v1.FrejaServiceData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.FrejaServiceData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.FrejaServiceData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.FrejaServiceData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.FrejaServiceData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.IntereastServiceData.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.IntereastServiceData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.IntereastServiceData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.IntereastServiceData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.IntereastServiceData}
 */
proto.swift.booking.v1.IntereastServiceData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.IntereastServiceData;
  return proto.swift.booking.v1.IntereastServiceData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.IntereastServiceData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.IntereastServiceData}
 */
proto.swift.booking.v1.IntereastServiceData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.IntereastServiceData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.IntereastServiceData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.IntereastServiceData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.IntereastServiceData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.K2ServiceData.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.K2ServiceData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.K2ServiceData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.K2ServiceData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.K2ServiceData}
 */
proto.swift.booking.v1.K2ServiceData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.K2ServiceData;
  return proto.swift.booking.v1.K2ServiceData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.K2ServiceData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.K2ServiceData}
 */
proto.swift.booking.v1.K2ServiceData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.K2ServiceData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.K2ServiceData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.K2ServiceData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.K2ServiceData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.UPSServiceData.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.UPSServiceData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.UPSServiceData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.UPSServiceData.toObject = function(includeInstance, msg) {
  var f, obj = {
serviceType: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.UPSServiceData}
 */
proto.swift.booking.v1.UPSServiceData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.UPSServiceData;
  return proto.swift.booking.v1.UPSServiceData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.UPSServiceData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.UPSServiceData}
 */
proto.swift.booking.v1.UPSServiceData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.swift.booking.v1.UPSServiceData.ServiceType} */ (reader.readEnum());
      msg.setServiceType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.UPSServiceData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.UPSServiceData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.UPSServiceData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.UPSServiceData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.swift.booking.v1.UPSServiceData.ServiceType = {
  OTHER: 0,
  EXPRESS: 1,
  EXPRESS_SAVER: 2,
  EXPRESS_PLUS: 3,
  STANDARD: 4,
  EXPEDITED: 5
};

/**
 * optional ServiceType service_type = 1;
 * @return {!proto.swift.booking.v1.UPSServiceData.ServiceType}
 */
proto.swift.booking.v1.UPSServiceData.prototype.getServiceType = function() {
  return /** @type {!proto.swift.booking.v1.UPSServiceData.ServiceType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.swift.booking.v1.UPSServiceData.ServiceType} value
 * @return {!proto.swift.booking.v1.UPSServiceData} returns this
 */
proto.swift.booking.v1.UPSServiceData.prototype.setServiceType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.booking.v1.SupplierConfig.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.SupplierConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.SupplierConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.SupplierConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.SupplierConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
owner: (f = msg.getOwner()) && common_owner_pb.Owner.toObject(includeInstance, f),
settingsList: jspb.Message.toObjectList(msg.getSettingsList(),
    proto.swift.booking.v1.SupplierSetting.toObject, includeInstance),
createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.SupplierConfig}
 */
proto.swift.booking.v1.SupplierConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.SupplierConfig;
  return proto.swift.booking.v1.SupplierConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.SupplierConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.SupplierConfig}
 */
proto.swift.booking.v1.SupplierConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_owner_pb.Owner;
      reader.readMessage(value,common_owner_pb.Owner.deserializeBinaryFromReader);
      msg.setOwner(value);
      break;
    case 2:
      var value = new proto.swift.booking.v1.SupplierSetting;
      reader.readMessage(value,proto.swift.booking.v1.SupplierSetting.deserializeBinaryFromReader);
      msg.addSettings(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.SupplierConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.SupplierConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.SupplierConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.SupplierConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwner();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_owner_pb.Owner.serializeBinaryToWriter
    );
  }
  f = message.getSettingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.swift.booking.v1.SupplierSetting.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional swift.common.Owner owner = 1;
 * @return {?proto.swift.common.Owner}
 */
proto.swift.booking.v1.SupplierConfig.prototype.getOwner = function() {
  return /** @type{?proto.swift.common.Owner} */ (
    jspb.Message.getWrapperField(this, common_owner_pb.Owner, 1));
};


/**
 * @param {?proto.swift.common.Owner|undefined} value
 * @return {!proto.swift.booking.v1.SupplierConfig} returns this
*/
proto.swift.booking.v1.SupplierConfig.prototype.setOwner = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.SupplierConfig} returns this
 */
proto.swift.booking.v1.SupplierConfig.prototype.clearOwner = function() {
  return this.setOwner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.SupplierConfig.prototype.hasOwner = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated SupplierSetting settings = 2;
 * @return {!Array<!proto.swift.booking.v1.SupplierSetting>}
 */
proto.swift.booking.v1.SupplierConfig.prototype.getSettingsList = function() {
  return /** @type{!Array<!proto.swift.booking.v1.SupplierSetting>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.booking.v1.SupplierSetting, 2));
};


/**
 * @param {!Array<!proto.swift.booking.v1.SupplierSetting>} value
 * @return {!proto.swift.booking.v1.SupplierConfig} returns this
*/
proto.swift.booking.v1.SupplierConfig.prototype.setSettingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.swift.booking.v1.SupplierSetting=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.booking.v1.SupplierSetting}
 */
proto.swift.booking.v1.SupplierConfig.prototype.addSettings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.swift.booking.v1.SupplierSetting, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.booking.v1.SupplierConfig} returns this
 */
proto.swift.booking.v1.SupplierConfig.prototype.clearSettingsList = function() {
  return this.setSettingsList([]);
};


/**
 * optional google.protobuf.Timestamp created_at = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.booking.v1.SupplierConfig.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.booking.v1.SupplierConfig} returns this
*/
proto.swift.booking.v1.SupplierConfig.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.SupplierConfig} returns this
 */
proto.swift.booking.v1.SupplierConfig.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.SupplierConfig.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.swift.booking.v1.SupplierConfig.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.swift.booking.v1.SupplierConfig} returns this
*/
proto.swift.booking.v1.SupplierConfig.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.SupplierConfig} returns this
 */
proto.swift.booking.v1.SupplierConfig.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.SupplierConfig.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.SupplierSetting.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.SupplierSetting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.SupplierSetting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.SupplierSetting.toObject = function(includeInstance, msg) {
  var f, obj = {
supplierId: jspb.Message.getFieldWithDefault(msg, 1, 0),
supplierType: jspb.Message.getFieldWithDefault(msg, 2, 0),
enabled: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
disablePricing: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
overrideBringData: (f = msg.getOverrideBringData()) && proto.swift.booking.v1.BringSupplierData.toObject(includeInstance, f),
overrideDhlData: (f = msg.getOverrideDhlData()) && proto.swift.booking.v1.DHLSupplierData.toObject(includeInstance, f),
overrideIntereastData: (f = msg.getOverrideIntereastData()) && proto.swift.booking.v1.IntereastSupplierData.toObject(includeInstance, f),
overrideK2Data: (f = msg.getOverrideK2Data()) && proto.swift.booking.v1.K2SupplierData.toObject(includeInstance, f),
overrideUpsData: (f = msg.getOverrideUpsData()) && proto.swift.booking.v1.UPSSupplierData.toObject(includeInstance, f),
disableSupplierCost: jspb.Message.getBooleanFieldWithDefault(msg, 10, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.SupplierSetting}
 */
proto.swift.booking.v1.SupplierSetting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.SupplierSetting;
  return proto.swift.booking.v1.SupplierSetting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.SupplierSetting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.SupplierSetting}
 */
proto.swift.booking.v1.SupplierSetting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSupplierId(value);
      break;
    case 2:
      var value = /** @type {!proto.swift.booking.v1.SupplierType} */ (reader.readEnum());
      msg.setSupplierType(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisablePricing(value);
      break;
    case 5:
      var value = new proto.swift.booking.v1.BringSupplierData;
      reader.readMessage(value,proto.swift.booking.v1.BringSupplierData.deserializeBinaryFromReader);
      msg.setOverrideBringData(value);
      break;
    case 6:
      var value = new proto.swift.booking.v1.DHLSupplierData;
      reader.readMessage(value,proto.swift.booking.v1.DHLSupplierData.deserializeBinaryFromReader);
      msg.setOverrideDhlData(value);
      break;
    case 7:
      var value = new proto.swift.booking.v1.IntereastSupplierData;
      reader.readMessage(value,proto.swift.booking.v1.IntereastSupplierData.deserializeBinaryFromReader);
      msg.setOverrideIntereastData(value);
      break;
    case 8:
      var value = new proto.swift.booking.v1.K2SupplierData;
      reader.readMessage(value,proto.swift.booking.v1.K2SupplierData.deserializeBinaryFromReader);
      msg.setOverrideK2Data(value);
      break;
    case 9:
      var value = new proto.swift.booking.v1.UPSSupplierData;
      reader.readMessage(value,proto.swift.booking.v1.UPSSupplierData.deserializeBinaryFromReader);
      msg.setOverrideUpsData(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisableSupplierCost(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.SupplierSetting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.SupplierSetting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.SupplierSetting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.SupplierSetting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSupplierId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSupplierType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getEnabled();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getDisablePricing();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getOverrideBringData();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.swift.booking.v1.BringSupplierData.serializeBinaryToWriter
    );
  }
  f = message.getOverrideDhlData();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.swift.booking.v1.DHLSupplierData.serializeBinaryToWriter
    );
  }
  f = message.getOverrideIntereastData();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.swift.booking.v1.IntereastSupplierData.serializeBinaryToWriter
    );
  }
  f = message.getOverrideK2Data();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.swift.booking.v1.K2SupplierData.serializeBinaryToWriter
    );
  }
  f = message.getOverrideUpsData();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.swift.booking.v1.UPSSupplierData.serializeBinaryToWriter
    );
  }
  f = message.getDisableSupplierCost();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
};


/**
 * optional int64 supplier_id = 1;
 * @return {number}
 */
proto.swift.booking.v1.SupplierSetting.prototype.getSupplierId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.SupplierSetting} returns this
 */
proto.swift.booking.v1.SupplierSetting.prototype.setSupplierId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional SupplierType supplier_type = 2;
 * @return {!proto.swift.booking.v1.SupplierType}
 */
proto.swift.booking.v1.SupplierSetting.prototype.getSupplierType = function() {
  return /** @type {!proto.swift.booking.v1.SupplierType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.swift.booking.v1.SupplierType} value
 * @return {!proto.swift.booking.v1.SupplierSetting} returns this
 */
proto.swift.booking.v1.SupplierSetting.prototype.setSupplierType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional bool enabled = 3;
 * @return {boolean}
 */
proto.swift.booking.v1.SupplierSetting.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.booking.v1.SupplierSetting} returns this
 */
proto.swift.booking.v1.SupplierSetting.prototype.setEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool disable_pricing = 4;
 * @return {boolean}
 */
proto.swift.booking.v1.SupplierSetting.prototype.getDisablePricing = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.booking.v1.SupplierSetting} returns this
 */
proto.swift.booking.v1.SupplierSetting.prototype.setDisablePricing = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional BringSupplierData override_bring_data = 5;
 * @return {?proto.swift.booking.v1.BringSupplierData}
 */
proto.swift.booking.v1.SupplierSetting.prototype.getOverrideBringData = function() {
  return /** @type{?proto.swift.booking.v1.BringSupplierData} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.BringSupplierData, 5));
};


/**
 * @param {?proto.swift.booking.v1.BringSupplierData|undefined} value
 * @return {!proto.swift.booking.v1.SupplierSetting} returns this
*/
proto.swift.booking.v1.SupplierSetting.prototype.setOverrideBringData = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.SupplierSetting} returns this
 */
proto.swift.booking.v1.SupplierSetting.prototype.clearOverrideBringData = function() {
  return this.setOverrideBringData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.SupplierSetting.prototype.hasOverrideBringData = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional DHLSupplierData override_dhl_data = 6;
 * @return {?proto.swift.booking.v1.DHLSupplierData}
 */
proto.swift.booking.v1.SupplierSetting.prototype.getOverrideDhlData = function() {
  return /** @type{?proto.swift.booking.v1.DHLSupplierData} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.DHLSupplierData, 6));
};


/**
 * @param {?proto.swift.booking.v1.DHLSupplierData|undefined} value
 * @return {!proto.swift.booking.v1.SupplierSetting} returns this
*/
proto.swift.booking.v1.SupplierSetting.prototype.setOverrideDhlData = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.SupplierSetting} returns this
 */
proto.swift.booking.v1.SupplierSetting.prototype.clearOverrideDhlData = function() {
  return this.setOverrideDhlData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.SupplierSetting.prototype.hasOverrideDhlData = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional IntereastSupplierData override_intereast_data = 7;
 * @return {?proto.swift.booking.v1.IntereastSupplierData}
 */
proto.swift.booking.v1.SupplierSetting.prototype.getOverrideIntereastData = function() {
  return /** @type{?proto.swift.booking.v1.IntereastSupplierData} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.IntereastSupplierData, 7));
};


/**
 * @param {?proto.swift.booking.v1.IntereastSupplierData|undefined} value
 * @return {!proto.swift.booking.v1.SupplierSetting} returns this
*/
proto.swift.booking.v1.SupplierSetting.prototype.setOverrideIntereastData = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.SupplierSetting} returns this
 */
proto.swift.booking.v1.SupplierSetting.prototype.clearOverrideIntereastData = function() {
  return this.setOverrideIntereastData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.SupplierSetting.prototype.hasOverrideIntereastData = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional K2SupplierData override_k2_data = 8;
 * @return {?proto.swift.booking.v1.K2SupplierData}
 */
proto.swift.booking.v1.SupplierSetting.prototype.getOverrideK2Data = function() {
  return /** @type{?proto.swift.booking.v1.K2SupplierData} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.K2SupplierData, 8));
};


/**
 * @param {?proto.swift.booking.v1.K2SupplierData|undefined} value
 * @return {!proto.swift.booking.v1.SupplierSetting} returns this
*/
proto.swift.booking.v1.SupplierSetting.prototype.setOverrideK2Data = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.SupplierSetting} returns this
 */
proto.swift.booking.v1.SupplierSetting.prototype.clearOverrideK2Data = function() {
  return this.setOverrideK2Data(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.SupplierSetting.prototype.hasOverrideK2Data = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional UPSSupplierData override_ups_data = 9;
 * @return {?proto.swift.booking.v1.UPSSupplierData}
 */
proto.swift.booking.v1.SupplierSetting.prototype.getOverrideUpsData = function() {
  return /** @type{?proto.swift.booking.v1.UPSSupplierData} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.UPSSupplierData, 9));
};


/**
 * @param {?proto.swift.booking.v1.UPSSupplierData|undefined} value
 * @return {!proto.swift.booking.v1.SupplierSetting} returns this
*/
proto.swift.booking.v1.SupplierSetting.prototype.setOverrideUpsData = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.SupplierSetting} returns this
 */
proto.swift.booking.v1.SupplierSetting.prototype.clearOverrideUpsData = function() {
  return this.setOverrideUpsData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.SupplierSetting.prototype.hasOverrideUpsData = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional bool disable_supplier_cost = 10;
 * @return {boolean}
 */
proto.swift.booking.v1.SupplierSetting.prototype.getDisableSupplierCost = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.booking.v1.SupplierSetting} returns this
 */
proto.swift.booking.v1.SupplierSetting.prototype.setDisableSupplierCost = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.ListSuppliersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.ListSuppliersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.ListSuppliersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.ListSuppliersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.ListSuppliersRequest}
 */
proto.swift.booking.v1.ListSuppliersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.ListSuppliersRequest;
  return proto.swift.booking.v1.ListSuppliersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.ListSuppliersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.ListSuppliersRequest}
 */
proto.swift.booking.v1.ListSuppliersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.ListSuppliersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.ListSuppliersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.ListSuppliersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.ListSuppliersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.booking.v1.ListSuppliersResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.ListSuppliersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.ListSuppliersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.ListSuppliersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.ListSuppliersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
totalCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
suppliersList: jspb.Message.toObjectList(msg.getSuppliersList(),
    proto.swift.booking.v1.Supplier.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.ListSuppliersResponse}
 */
proto.swift.booking.v1.ListSuppliersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.ListSuppliersResponse;
  return proto.swift.booking.v1.ListSuppliersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.ListSuppliersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.ListSuppliersResponse}
 */
proto.swift.booking.v1.ListSuppliersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalCount(value);
      break;
    case 2:
      var value = new proto.swift.booking.v1.Supplier;
      reader.readMessage(value,proto.swift.booking.v1.Supplier.deserializeBinaryFromReader);
      msg.addSuppliers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.ListSuppliersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.ListSuppliersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.ListSuppliersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.ListSuppliersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSuppliersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.swift.booking.v1.Supplier.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 total_count = 1;
 * @return {number}
 */
proto.swift.booking.v1.ListSuppliersResponse.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.ListSuppliersResponse} returns this
 */
proto.swift.booking.v1.ListSuppliersResponse.prototype.setTotalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated Supplier suppliers = 2;
 * @return {!Array<!proto.swift.booking.v1.Supplier>}
 */
proto.swift.booking.v1.ListSuppliersResponse.prototype.getSuppliersList = function() {
  return /** @type{!Array<!proto.swift.booking.v1.Supplier>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.booking.v1.Supplier, 2));
};


/**
 * @param {!Array<!proto.swift.booking.v1.Supplier>} value
 * @return {!proto.swift.booking.v1.ListSuppliersResponse} returns this
*/
proto.swift.booking.v1.ListSuppliersResponse.prototype.setSuppliersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.swift.booking.v1.Supplier=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.booking.v1.Supplier}
 */
proto.swift.booking.v1.ListSuppliersResponse.prototype.addSuppliers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.swift.booking.v1.Supplier, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.booking.v1.ListSuppliersResponse} returns this
 */
proto.swift.booking.v1.ListSuppliersResponse.prototype.clearSuppliersList = function() {
  return this.setSuppliersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.ListConfigsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.ListConfigsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.ListConfigsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.ListConfigsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.ListConfigsRequest}
 */
proto.swift.booking.v1.ListConfigsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.ListConfigsRequest;
  return proto.swift.booking.v1.ListConfigsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.ListConfigsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.ListConfigsRequest}
 */
proto.swift.booking.v1.ListConfigsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.ListConfigsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.ListConfigsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.ListConfigsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.ListConfigsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.swift.booking.v1.ListConfigsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.ListConfigsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.ListConfigsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.ListConfigsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.ListConfigsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
totalCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
configsList: jspb.Message.toObjectList(msg.getConfigsList(),
    proto.swift.booking.v1.SupplierConfig.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.ListConfigsResponse}
 */
proto.swift.booking.v1.ListConfigsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.ListConfigsResponse;
  return proto.swift.booking.v1.ListConfigsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.ListConfigsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.ListConfigsResponse}
 */
proto.swift.booking.v1.ListConfigsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalCount(value);
      break;
    case 2:
      var value = new proto.swift.booking.v1.SupplierConfig;
      reader.readMessage(value,proto.swift.booking.v1.SupplierConfig.deserializeBinaryFromReader);
      msg.addConfigs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.ListConfigsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.ListConfigsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.ListConfigsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.ListConfigsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getConfigsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.swift.booking.v1.SupplierConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 total_count = 1;
 * @return {number}
 */
proto.swift.booking.v1.ListConfigsResponse.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.ListConfigsResponse} returns this
 */
proto.swift.booking.v1.ListConfigsResponse.prototype.setTotalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated SupplierConfig configs = 2;
 * @return {!Array<!proto.swift.booking.v1.SupplierConfig>}
 */
proto.swift.booking.v1.ListConfigsResponse.prototype.getConfigsList = function() {
  return /** @type{!Array<!proto.swift.booking.v1.SupplierConfig>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.swift.booking.v1.SupplierConfig, 2));
};


/**
 * @param {!Array<!proto.swift.booking.v1.SupplierConfig>} value
 * @return {!proto.swift.booking.v1.ListConfigsResponse} returns this
*/
proto.swift.booking.v1.ListConfigsResponse.prototype.setConfigsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.swift.booking.v1.SupplierConfig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.swift.booking.v1.SupplierConfig}
 */
proto.swift.booking.v1.ListConfigsResponse.prototype.addConfigs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.swift.booking.v1.SupplierConfig, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.swift.booking.v1.ListConfigsResponse} returns this
 */
proto.swift.booking.v1.ListConfigsResponse.prototype.clearConfigsList = function() {
  return this.setConfigsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.GetSupplierRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.GetSupplierRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.GetSupplierRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.GetSupplierRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
supplierId: jspb.Message.getFieldWithDefault(msg, 1, 0),
supplierRef: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.GetSupplierRequest}
 */
proto.swift.booking.v1.GetSupplierRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.GetSupplierRequest;
  return proto.swift.booking.v1.GetSupplierRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.GetSupplierRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.GetSupplierRequest}
 */
proto.swift.booking.v1.GetSupplierRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSupplierId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplierRef(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.GetSupplierRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.GetSupplierRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.GetSupplierRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.GetSupplierRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSupplierId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSupplierRef();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 supplier_id = 1;
 * @return {number}
 */
proto.swift.booking.v1.GetSupplierRequest.prototype.getSupplierId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.GetSupplierRequest} returns this
 */
proto.swift.booking.v1.GetSupplierRequest.prototype.setSupplierId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string supplier_ref = 2;
 * @return {string}
 */
proto.swift.booking.v1.GetSupplierRequest.prototype.getSupplierRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.swift.booking.v1.GetSupplierRequest} returns this
 */
proto.swift.booking.v1.GetSupplierRequest.prototype.setSupplierRef = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.GetSupplierResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.GetSupplierResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.GetSupplierResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.GetSupplierResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
supplier: (f = msg.getSupplier()) && proto.swift.booking.v1.Supplier.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.GetSupplierResponse}
 */
proto.swift.booking.v1.GetSupplierResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.GetSupplierResponse;
  return proto.swift.booking.v1.GetSupplierResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.GetSupplierResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.GetSupplierResponse}
 */
proto.swift.booking.v1.GetSupplierResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.Supplier;
      reader.readMessage(value,proto.swift.booking.v1.Supplier.deserializeBinaryFromReader);
      msg.setSupplier(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.GetSupplierResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.GetSupplierResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.GetSupplierResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.GetSupplierResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSupplier();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.booking.v1.Supplier.serializeBinaryToWriter
    );
  }
};


/**
 * optional Supplier supplier = 1;
 * @return {?proto.swift.booking.v1.Supplier}
 */
proto.swift.booking.v1.GetSupplierResponse.prototype.getSupplier = function() {
  return /** @type{?proto.swift.booking.v1.Supplier} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.Supplier, 1));
};


/**
 * @param {?proto.swift.booking.v1.Supplier|undefined} value
 * @return {!proto.swift.booking.v1.GetSupplierResponse} returns this
*/
proto.swift.booking.v1.GetSupplierResponse.prototype.setSupplier = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.GetSupplierResponse} returns this
 */
proto.swift.booking.v1.GetSupplierResponse.prototype.clearSupplier = function() {
  return this.setSupplier(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.GetSupplierResponse.prototype.hasSupplier = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.GetSupplierConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.GetSupplierConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.GetSupplierConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.GetSupplierConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
organizationId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.GetSupplierConfigRequest}
 */
proto.swift.booking.v1.GetSupplierConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.GetSupplierConfigRequest;
  return proto.swift.booking.v1.GetSupplierConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.GetSupplierConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.GetSupplierConfigRequest}
 */
proto.swift.booking.v1.GetSupplierConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrganizationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.GetSupplierConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.GetSupplierConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.GetSupplierConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.GetSupplierConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganizationId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 organization_id = 1;
 * @return {number}
 */
proto.swift.booking.v1.GetSupplierConfigRequest.prototype.getOrganizationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.GetSupplierConfigRequest} returns this
 */
proto.swift.booking.v1.GetSupplierConfigRequest.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.GetSupplierConfigResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.GetSupplierConfigResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.GetSupplierConfigResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.GetSupplierConfigResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
config: (f = msg.getConfig()) && proto.swift.booking.v1.SupplierConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.GetSupplierConfigResponse}
 */
proto.swift.booking.v1.GetSupplierConfigResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.GetSupplierConfigResponse;
  return proto.swift.booking.v1.GetSupplierConfigResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.GetSupplierConfigResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.GetSupplierConfigResponse}
 */
proto.swift.booking.v1.GetSupplierConfigResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.SupplierConfig;
      reader.readMessage(value,proto.swift.booking.v1.SupplierConfig.deserializeBinaryFromReader);
      msg.setConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.GetSupplierConfigResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.GetSupplierConfigResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.GetSupplierConfigResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.GetSupplierConfigResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConfig();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.booking.v1.SupplierConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional SupplierConfig config = 1;
 * @return {?proto.swift.booking.v1.SupplierConfig}
 */
proto.swift.booking.v1.GetSupplierConfigResponse.prototype.getConfig = function() {
  return /** @type{?proto.swift.booking.v1.SupplierConfig} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.SupplierConfig, 1));
};


/**
 * @param {?proto.swift.booking.v1.SupplierConfig|undefined} value
 * @return {!proto.swift.booking.v1.GetSupplierConfigResponse} returns this
*/
proto.swift.booking.v1.GetSupplierConfigResponse.prototype.setConfig = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.GetSupplierConfigResponse} returns this
 */
proto.swift.booking.v1.GetSupplierConfigResponse.prototype.clearConfig = function() {
  return this.setConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.GetSupplierConfigResponse.prototype.hasConfig = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.CreateSupplierRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.CreateSupplierRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.CreateSupplierRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.CreateSupplierRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
supplier: (f = msg.getSupplier()) && proto.swift.booking.v1.Supplier.toObject(includeInstance, f),
disablePricing: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.CreateSupplierRequest}
 */
proto.swift.booking.v1.CreateSupplierRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.CreateSupplierRequest;
  return proto.swift.booking.v1.CreateSupplierRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.CreateSupplierRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.CreateSupplierRequest}
 */
proto.swift.booking.v1.CreateSupplierRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.Supplier;
      reader.readMessage(value,proto.swift.booking.v1.Supplier.deserializeBinaryFromReader);
      msg.setSupplier(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisablePricing(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.CreateSupplierRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.CreateSupplierRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.CreateSupplierRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.CreateSupplierRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSupplier();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.booking.v1.Supplier.serializeBinaryToWriter
    );
  }
  f = message.getDisablePricing();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional Supplier supplier = 1;
 * @return {?proto.swift.booking.v1.Supplier}
 */
proto.swift.booking.v1.CreateSupplierRequest.prototype.getSupplier = function() {
  return /** @type{?proto.swift.booking.v1.Supplier} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.Supplier, 1));
};


/**
 * @param {?proto.swift.booking.v1.Supplier|undefined} value
 * @return {!proto.swift.booking.v1.CreateSupplierRequest} returns this
*/
proto.swift.booking.v1.CreateSupplierRequest.prototype.setSupplier = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.CreateSupplierRequest} returns this
 */
proto.swift.booking.v1.CreateSupplierRequest.prototype.clearSupplier = function() {
  return this.setSupplier(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.CreateSupplierRequest.prototype.hasSupplier = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool disable_pricing = 2;
 * @return {boolean}
 */
proto.swift.booking.v1.CreateSupplierRequest.prototype.getDisablePricing = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.swift.booking.v1.CreateSupplierRequest} returns this
 */
proto.swift.booking.v1.CreateSupplierRequest.prototype.setDisablePricing = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.CreateSupplierResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.CreateSupplierResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.CreateSupplierResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.CreateSupplierResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
supplier: (f = msg.getSupplier()) && proto.swift.booking.v1.Supplier.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.CreateSupplierResponse}
 */
proto.swift.booking.v1.CreateSupplierResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.CreateSupplierResponse;
  return proto.swift.booking.v1.CreateSupplierResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.CreateSupplierResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.CreateSupplierResponse}
 */
proto.swift.booking.v1.CreateSupplierResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.Supplier;
      reader.readMessage(value,proto.swift.booking.v1.Supplier.deserializeBinaryFromReader);
      msg.setSupplier(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.CreateSupplierResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.CreateSupplierResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.CreateSupplierResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.CreateSupplierResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSupplier();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.booking.v1.Supplier.serializeBinaryToWriter
    );
  }
};


/**
 * optional Supplier supplier = 1;
 * @return {?proto.swift.booking.v1.Supplier}
 */
proto.swift.booking.v1.CreateSupplierResponse.prototype.getSupplier = function() {
  return /** @type{?proto.swift.booking.v1.Supplier} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.Supplier, 1));
};


/**
 * @param {?proto.swift.booking.v1.Supplier|undefined} value
 * @return {!proto.swift.booking.v1.CreateSupplierResponse} returns this
*/
proto.swift.booking.v1.CreateSupplierResponse.prototype.setSupplier = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.CreateSupplierResponse} returns this
 */
proto.swift.booking.v1.CreateSupplierResponse.prototype.clearSupplier = function() {
  return this.setSupplier(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.CreateSupplierResponse.prototype.hasSupplier = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.CreateSupplierServiceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.CreateSupplierServiceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.CreateSupplierServiceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.CreateSupplierServiceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
service: (f = msg.getService()) && proto.swift.booking.v1.SupplierService.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.CreateSupplierServiceRequest}
 */
proto.swift.booking.v1.CreateSupplierServiceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.CreateSupplierServiceRequest;
  return proto.swift.booking.v1.CreateSupplierServiceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.CreateSupplierServiceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.CreateSupplierServiceRequest}
 */
proto.swift.booking.v1.CreateSupplierServiceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.SupplierService;
      reader.readMessage(value,proto.swift.booking.v1.SupplierService.deserializeBinaryFromReader);
      msg.setService(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.CreateSupplierServiceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.CreateSupplierServiceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.CreateSupplierServiceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.CreateSupplierServiceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getService();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.booking.v1.SupplierService.serializeBinaryToWriter
    );
  }
};


/**
 * optional SupplierService service = 1;
 * @return {?proto.swift.booking.v1.SupplierService}
 */
proto.swift.booking.v1.CreateSupplierServiceRequest.prototype.getService = function() {
  return /** @type{?proto.swift.booking.v1.SupplierService} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.SupplierService, 1));
};


/**
 * @param {?proto.swift.booking.v1.SupplierService|undefined} value
 * @return {!proto.swift.booking.v1.CreateSupplierServiceRequest} returns this
*/
proto.swift.booking.v1.CreateSupplierServiceRequest.prototype.setService = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.CreateSupplierServiceRequest} returns this
 */
proto.swift.booking.v1.CreateSupplierServiceRequest.prototype.clearService = function() {
  return this.setService(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.CreateSupplierServiceRequest.prototype.hasService = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.CreateSupplierServiceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.CreateSupplierServiceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.CreateSupplierServiceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.CreateSupplierServiceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
service: (f = msg.getService()) && proto.swift.booking.v1.SupplierService.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.CreateSupplierServiceResponse}
 */
proto.swift.booking.v1.CreateSupplierServiceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.CreateSupplierServiceResponse;
  return proto.swift.booking.v1.CreateSupplierServiceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.CreateSupplierServiceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.CreateSupplierServiceResponse}
 */
proto.swift.booking.v1.CreateSupplierServiceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.SupplierService;
      reader.readMessage(value,proto.swift.booking.v1.SupplierService.deserializeBinaryFromReader);
      msg.setService(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.CreateSupplierServiceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.CreateSupplierServiceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.CreateSupplierServiceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.CreateSupplierServiceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getService();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.booking.v1.SupplierService.serializeBinaryToWriter
    );
  }
};


/**
 * optional SupplierService service = 1;
 * @return {?proto.swift.booking.v1.SupplierService}
 */
proto.swift.booking.v1.CreateSupplierServiceResponse.prototype.getService = function() {
  return /** @type{?proto.swift.booking.v1.SupplierService} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.SupplierService, 1));
};


/**
 * @param {?proto.swift.booking.v1.SupplierService|undefined} value
 * @return {!proto.swift.booking.v1.CreateSupplierServiceResponse} returns this
*/
proto.swift.booking.v1.CreateSupplierServiceResponse.prototype.setService = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.CreateSupplierServiceResponse} returns this
 */
proto.swift.booking.v1.CreateSupplierServiceResponse.prototype.clearService = function() {
  return this.setService(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.CreateSupplierServiceResponse.prototype.hasService = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.EditSupplierRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.EditSupplierRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.EditSupplierRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.EditSupplierRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
supplier: (f = msg.getSupplier()) && proto.swift.booking.v1.Supplier.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.EditSupplierRequest}
 */
proto.swift.booking.v1.EditSupplierRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.EditSupplierRequest;
  return proto.swift.booking.v1.EditSupplierRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.EditSupplierRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.EditSupplierRequest}
 */
proto.swift.booking.v1.EditSupplierRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.Supplier;
      reader.readMessage(value,proto.swift.booking.v1.Supplier.deserializeBinaryFromReader);
      msg.setSupplier(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.EditSupplierRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.EditSupplierRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.EditSupplierRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.EditSupplierRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSupplier();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.booking.v1.Supplier.serializeBinaryToWriter
    );
  }
};


/**
 * optional Supplier supplier = 1;
 * @return {?proto.swift.booking.v1.Supplier}
 */
proto.swift.booking.v1.EditSupplierRequest.prototype.getSupplier = function() {
  return /** @type{?proto.swift.booking.v1.Supplier} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.Supplier, 1));
};


/**
 * @param {?proto.swift.booking.v1.Supplier|undefined} value
 * @return {!proto.swift.booking.v1.EditSupplierRequest} returns this
*/
proto.swift.booking.v1.EditSupplierRequest.prototype.setSupplier = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.EditSupplierRequest} returns this
 */
proto.swift.booking.v1.EditSupplierRequest.prototype.clearSupplier = function() {
  return this.setSupplier(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.EditSupplierRequest.prototype.hasSupplier = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.EditSupplierResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.EditSupplierResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.EditSupplierResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.EditSupplierResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
supplier: (f = msg.getSupplier()) && proto.swift.booking.v1.Supplier.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.EditSupplierResponse}
 */
proto.swift.booking.v1.EditSupplierResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.EditSupplierResponse;
  return proto.swift.booking.v1.EditSupplierResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.EditSupplierResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.EditSupplierResponse}
 */
proto.swift.booking.v1.EditSupplierResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.Supplier;
      reader.readMessage(value,proto.swift.booking.v1.Supplier.deserializeBinaryFromReader);
      msg.setSupplier(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.EditSupplierResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.EditSupplierResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.EditSupplierResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.EditSupplierResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSupplier();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.booking.v1.Supplier.serializeBinaryToWriter
    );
  }
};


/**
 * optional Supplier supplier = 1;
 * @return {?proto.swift.booking.v1.Supplier}
 */
proto.swift.booking.v1.EditSupplierResponse.prototype.getSupplier = function() {
  return /** @type{?proto.swift.booking.v1.Supplier} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.Supplier, 1));
};


/**
 * @param {?proto.swift.booking.v1.Supplier|undefined} value
 * @return {!proto.swift.booking.v1.EditSupplierResponse} returns this
*/
proto.swift.booking.v1.EditSupplierResponse.prototype.setSupplier = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.EditSupplierResponse} returns this
 */
proto.swift.booking.v1.EditSupplierResponse.prototype.clearSupplier = function() {
  return this.setSupplier(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.EditSupplierResponse.prototype.hasSupplier = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.EditSupplierServiceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.EditSupplierServiceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.EditSupplierServiceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.EditSupplierServiceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
service: (f = msg.getService()) && proto.swift.booking.v1.SupplierService.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.EditSupplierServiceRequest}
 */
proto.swift.booking.v1.EditSupplierServiceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.EditSupplierServiceRequest;
  return proto.swift.booking.v1.EditSupplierServiceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.EditSupplierServiceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.EditSupplierServiceRequest}
 */
proto.swift.booking.v1.EditSupplierServiceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.SupplierService;
      reader.readMessage(value,proto.swift.booking.v1.SupplierService.deserializeBinaryFromReader);
      msg.setService(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.EditSupplierServiceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.EditSupplierServiceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.EditSupplierServiceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.EditSupplierServiceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getService();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.booking.v1.SupplierService.serializeBinaryToWriter
    );
  }
};


/**
 * optional SupplierService service = 1;
 * @return {?proto.swift.booking.v1.SupplierService}
 */
proto.swift.booking.v1.EditSupplierServiceRequest.prototype.getService = function() {
  return /** @type{?proto.swift.booking.v1.SupplierService} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.SupplierService, 1));
};


/**
 * @param {?proto.swift.booking.v1.SupplierService|undefined} value
 * @return {!proto.swift.booking.v1.EditSupplierServiceRequest} returns this
*/
proto.swift.booking.v1.EditSupplierServiceRequest.prototype.setService = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.EditSupplierServiceRequest} returns this
 */
proto.swift.booking.v1.EditSupplierServiceRequest.prototype.clearService = function() {
  return this.setService(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.EditSupplierServiceRequest.prototype.hasService = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.EditSupplierServiceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.EditSupplierServiceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.EditSupplierServiceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.EditSupplierServiceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
service: (f = msg.getService()) && proto.swift.booking.v1.SupplierService.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.EditSupplierServiceResponse}
 */
proto.swift.booking.v1.EditSupplierServiceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.EditSupplierServiceResponse;
  return proto.swift.booking.v1.EditSupplierServiceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.EditSupplierServiceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.EditSupplierServiceResponse}
 */
proto.swift.booking.v1.EditSupplierServiceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.SupplierService;
      reader.readMessage(value,proto.swift.booking.v1.SupplierService.deserializeBinaryFromReader);
      msg.setService(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.EditSupplierServiceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.EditSupplierServiceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.EditSupplierServiceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.EditSupplierServiceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getService();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.booking.v1.SupplierService.serializeBinaryToWriter
    );
  }
};


/**
 * optional SupplierService service = 1;
 * @return {?proto.swift.booking.v1.SupplierService}
 */
proto.swift.booking.v1.EditSupplierServiceResponse.prototype.getService = function() {
  return /** @type{?proto.swift.booking.v1.SupplierService} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.SupplierService, 1));
};


/**
 * @param {?proto.swift.booking.v1.SupplierService|undefined} value
 * @return {!proto.swift.booking.v1.EditSupplierServiceResponse} returns this
*/
proto.swift.booking.v1.EditSupplierServiceResponse.prototype.setService = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.EditSupplierServiceResponse} returns this
 */
proto.swift.booking.v1.EditSupplierServiceResponse.prototype.clearService = function() {
  return this.setService(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.EditSupplierServiceResponse.prototype.hasService = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.SetSupplierConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.SetSupplierConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.SetSupplierConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.SetSupplierConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
config: (f = msg.getConfig()) && proto.swift.booking.v1.SupplierConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.SetSupplierConfigRequest}
 */
proto.swift.booking.v1.SetSupplierConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.SetSupplierConfigRequest;
  return proto.swift.booking.v1.SetSupplierConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.SetSupplierConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.SetSupplierConfigRequest}
 */
proto.swift.booking.v1.SetSupplierConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.SupplierConfig;
      reader.readMessage(value,proto.swift.booking.v1.SupplierConfig.deserializeBinaryFromReader);
      msg.setConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.SetSupplierConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.SetSupplierConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.SetSupplierConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.SetSupplierConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConfig();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.booking.v1.SupplierConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional SupplierConfig config = 1;
 * @return {?proto.swift.booking.v1.SupplierConfig}
 */
proto.swift.booking.v1.SetSupplierConfigRequest.prototype.getConfig = function() {
  return /** @type{?proto.swift.booking.v1.SupplierConfig} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.SupplierConfig, 1));
};


/**
 * @param {?proto.swift.booking.v1.SupplierConfig|undefined} value
 * @return {!proto.swift.booking.v1.SetSupplierConfigRequest} returns this
*/
proto.swift.booking.v1.SetSupplierConfigRequest.prototype.setConfig = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.SetSupplierConfigRequest} returns this
 */
proto.swift.booking.v1.SetSupplierConfigRequest.prototype.clearConfig = function() {
  return this.setConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.SetSupplierConfigRequest.prototype.hasConfig = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.SetSupplierConfigResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.SetSupplierConfigResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.SetSupplierConfigResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.SetSupplierConfigResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
config: (f = msg.getConfig()) && proto.swift.booking.v1.SupplierConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.SetSupplierConfigResponse}
 */
proto.swift.booking.v1.SetSupplierConfigResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.SetSupplierConfigResponse;
  return proto.swift.booking.v1.SetSupplierConfigResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.SetSupplierConfigResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.SetSupplierConfigResponse}
 */
proto.swift.booking.v1.SetSupplierConfigResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.swift.booking.v1.SupplierConfig;
      reader.readMessage(value,proto.swift.booking.v1.SupplierConfig.deserializeBinaryFromReader);
      msg.setConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.SetSupplierConfigResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.SetSupplierConfigResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.SetSupplierConfigResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.SetSupplierConfigResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConfig();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.swift.booking.v1.SupplierConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional SupplierConfig config = 1;
 * @return {?proto.swift.booking.v1.SupplierConfig}
 */
proto.swift.booking.v1.SetSupplierConfigResponse.prototype.getConfig = function() {
  return /** @type{?proto.swift.booking.v1.SupplierConfig} */ (
    jspb.Message.getWrapperField(this, proto.swift.booking.v1.SupplierConfig, 1));
};


/**
 * @param {?proto.swift.booking.v1.SupplierConfig|undefined} value
 * @return {!proto.swift.booking.v1.SetSupplierConfigResponse} returns this
*/
proto.swift.booking.v1.SetSupplierConfigResponse.prototype.setConfig = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.swift.booking.v1.SetSupplierConfigResponse} returns this
 */
proto.swift.booking.v1.SetSupplierConfigResponse.prototype.clearConfig = function() {
  return this.setConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.swift.booking.v1.SetSupplierConfigResponse.prototype.hasConfig = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.DeleteSupplierRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.DeleteSupplierRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.DeleteSupplierRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.DeleteSupplierRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
supplierId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.DeleteSupplierRequest}
 */
proto.swift.booking.v1.DeleteSupplierRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.DeleteSupplierRequest;
  return proto.swift.booking.v1.DeleteSupplierRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.DeleteSupplierRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.DeleteSupplierRequest}
 */
proto.swift.booking.v1.DeleteSupplierRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSupplierId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.DeleteSupplierRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.DeleteSupplierRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.DeleteSupplierRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.DeleteSupplierRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSupplierId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 supplier_id = 1;
 * @return {number}
 */
proto.swift.booking.v1.DeleteSupplierRequest.prototype.getSupplierId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.DeleteSupplierRequest} returns this
 */
proto.swift.booking.v1.DeleteSupplierRequest.prototype.setSupplierId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.DeleteSupplierResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.DeleteSupplierResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.DeleteSupplierResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.DeleteSupplierResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.DeleteSupplierResponse}
 */
proto.swift.booking.v1.DeleteSupplierResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.DeleteSupplierResponse;
  return proto.swift.booking.v1.DeleteSupplierResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.DeleteSupplierResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.DeleteSupplierResponse}
 */
proto.swift.booking.v1.DeleteSupplierResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.DeleteSupplierResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.DeleteSupplierResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.DeleteSupplierResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.DeleteSupplierResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.DeleteSupplierServiceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.DeleteSupplierServiceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.DeleteSupplierServiceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.DeleteSupplierServiceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
serviceId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.DeleteSupplierServiceRequest}
 */
proto.swift.booking.v1.DeleteSupplierServiceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.DeleteSupplierServiceRequest;
  return proto.swift.booking.v1.DeleteSupplierServiceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.DeleteSupplierServiceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.DeleteSupplierServiceRequest}
 */
proto.swift.booking.v1.DeleteSupplierServiceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setServiceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.DeleteSupplierServiceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.DeleteSupplierServiceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.DeleteSupplierServiceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.DeleteSupplierServiceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 service_id = 1;
 * @return {number}
 */
proto.swift.booking.v1.DeleteSupplierServiceRequest.prototype.getServiceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.swift.booking.v1.DeleteSupplierServiceRequest} returns this
 */
proto.swift.booking.v1.DeleteSupplierServiceRequest.prototype.setServiceId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.swift.booking.v1.DeleteSupplierServiceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.swift.booking.v1.DeleteSupplierServiceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.swift.booking.v1.DeleteSupplierServiceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.DeleteSupplierServiceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.swift.booking.v1.DeleteSupplierServiceResponse}
 */
proto.swift.booking.v1.DeleteSupplierServiceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.swift.booking.v1.DeleteSupplierServiceResponse;
  return proto.swift.booking.v1.DeleteSupplierServiceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.swift.booking.v1.DeleteSupplierServiceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.swift.booking.v1.DeleteSupplierServiceResponse}
 */
proto.swift.booking.v1.DeleteSupplierServiceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.swift.booking.v1.DeleteSupplierServiceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.swift.booking.v1.DeleteSupplierServiceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.swift.booking.v1.DeleteSupplierServiceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.swift.booking.v1.DeleteSupplierServiceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.swift.booking.v1.SupplierType = {
  NONE: 0,
  BRING: 1,
  DHL: 2,
  FREJA: 3,
  INTEREAST: 4,
  K2: 5,
  UPS: 6,
  TEMPLATE: 7
};

goog.object.extend(exports, proto.swift.booking.v1);
