import React from 'react'

import { Form } from 'antd'
import Dropdown from 'antd/es/dropdown/dropdown'
import { ItemType } from 'rc-menu/es/interface'

import { SecondaryButton } from 'components/Buttons'
import { GhostButton } from 'components/Buttons/components/GhostButton'

import './Dropdown.css'

interface DropdownProps {
  label?: string
  iconBefore?: React.JSX.Element
  iconAfter?: React.JSX.Element
  buttonLabel: string
  items: ItemType[]
  trigger?: 'click'
  noMargin?: boolean
  disabled?: boolean
  buttonType?: 'secondary' | 'ghost'
}

export const DropdownComponent = ({
  label,
  iconBefore,
  iconAfter,
  items,
  buttonLabel,
  noMargin,
  trigger = 'click',
  disabled,
  buttonType = 'secondary',
}: DropdownProps) => {
  return (
    <Form.Item label={label} className={noMargin ? 'dropdown-component--no-margin' : ''}>
      <Dropdown
        menu={{ items }}
        trigger={[trigger, 'hover', 'contextMenu']}
        disabled={disabled}
        autoAdjustOverflow={true}
        overlayClassName="dropdown-component__menu"
        className="dropdown-component__menu"
      >
        {buttonType === 'secondary' ? (
          <SecondaryButton label={buttonLabel} iconBefore={iconBefore} iconAfter={iconAfter} />
        ) : (
          <GhostButton label={buttonLabel} iconBefore={iconBefore} iconAfter={iconAfter} />
        )}
      </Dropdown>
    </Form.Item>
  )
}
