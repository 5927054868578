import { useState, useSyncExternalStore } from 'react'

export const useMenuCollapse = () => {
  const storageKey = `menuCollapsed`
  const [collapsed, setCollapsed] = useState(false)
  const toggleCollapse = () => {
    setCollapsed((current) => {
      window.localStorage.setItem(storageKey, JSON.stringify(!collapsed))
      window.dispatchEvent(
        new StorageEvent('storage', { key: storageKey, newValue: JSON.stringify(!collapsed) }),
      )
      return !current
    })
  }

  const getSnapshot = () => window.localStorage.getItem(storageKey)

  const parseSnapshot = (snapshot: string | null): boolean => {
    try {
      return snapshot ? JSON.parse(snapshot) : false
    } catch {
      return false
    }
  }

  const getCollapsed = () => parseSnapshot(getSnapshot())

  const subscribe = (listener: () => void) => {
    window.addEventListener('storage', listener)
    return () => void window.removeEventListener('storage', listener)
  }

  const store = useSyncExternalStore(subscribe, getSnapshot)

  return [toggleCollapse, getCollapsed, store] as const
}
