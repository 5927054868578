import * as React from 'react'

import Icon from '@ant-design/icons'
import { Popover } from 'antd'
import { PopoverProps } from 'antd/es/popover'

import { InfoCircle } from '../icons'

interface Props extends PopoverProps {
  children: React.ReactNode
}

export const PopoverIndicator: React.FC<Props> = ({ children, ...props }) => {
  return (
    <Popover placement="right" {...props}>
      <span>
        {children}
        {!!props.content && (
          <Icon
            component={InfoCircle}
            style={{
              color: '#44474f',
              fontSize: '9px',
              verticalAlign: '0.2em',
              paddingLeft: '3px',
              opacity: 0.7,
            }}
          />
        )}
      </span>
    </Popover>
  )
}
